import { String } from '../platform/string';

export class ContextActions {
    private InvetoryContext : { [IdContext:string] :  object } = {};

    /**
     * Registe a context
     *
     * @param {string} context
     * @param {object} owner
     * @memberof ContextActions
     */
    public Register(context: string,  owner: object){
        this.InvetoryContext[context] = owner;
    }

    /**
     * receives a data with info to execute a action
     *
     * @param {*} dataActions
     * [
     *    {
     *        'Context' : value,
     *        'Action' : value,
     *        'Parameters : [ { value } , ... ]
     *    }
     * ]
     *  @memberof ContextActions
     */
    public Execute(dataActions: any){
        for (let index = 0; index < dataActions.length; index++) {
            const action = dataActions[index];
            let method = undefined;

            if ((method = this.GetMethod(action.Context, action.Action)) != null){
                var owner:any = this.InvetoryContext[action.Context];
                if (owner['CanBeApply'] && owner.CanBeApply(action.Action)) {
                    method = undefined;
                }
                if (method){
                    try {
                        method.apply(owner, action.Parameters);
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
        }
    }

    private GetMethod(context: string, action: string): Function{
        var method:Function = undefined;
        if (context && action) {
            method = this.InvetoryContext[context][action];
            if (!method) {
               console.log(String.Format('Action: ({0}) does not found in context : ({1})', action, context));
            }
        } else {
            console.log(String.Format("invalid parameters: context({0}) , action({1})", context, action));
        }
        return method;
    }
}
