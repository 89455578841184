import { Component, Input } from '@angular/core';

@Component({
  selector: 'bt-slider-text',
  templateUrl: './slider-text.component.html',
  styleUrls: ['./slider-text.component.css']
})
export class SliderTextComponent {
  @Input() text: string;

  constructor() { }
}
