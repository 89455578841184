import { ElementRef } from '@angular/core';
import { TextAnchorWidth, TextAnchorHeight } from './enums';

/**
 * Apply format of wrap to text in svg text element
 *
 * @export
 * @param {ElementRef} elementDOM
 * @param {string} text
 * @param {number} x
 * @param {number} y
 * @param {number} sizeWidth value from text
 * @param {number} sizeHeight value from text
 * @param {TextAnchorWidth} anchorWidth
 * @param {number} width value fron container
 * @param {TextAnchorHeight} anchorHeight
 * @param {number} height value fron container
 * @param {string} [fontfamily]
 */
export function SvgText(
        elementDOM: ElementRef,
        text: string,
        sizeWidth: number,
        sizeHeight: number,
        anchorWidth: TextAnchorWidth,
        width: number,
        anchorHeight: TextAnchorHeight,
        height: number,
        fontfamily?:string
    ) {
    //split in words
    var words: string[] = text.split(' ');
    var line: string  = '';
    var lines:string[] = new Array;
    var numlines: number = 0;

    if ( words.length > 0){
        //generate lines
        line += words[0];
        for (var i: number = 1; i < words.length; i++) {
            var lengthWord: number = words[i].length * sizeWidth;
            var lengthLine:number = line.length * sizeWidth;
            if (lengthLine + lengthWord <= width){
                line += " " + words[i];
            } else {
                lines[numlines] = line;
                numlines++;
                line = words[i];
            }
        }

        if (words.length >= 1){
            lines[numlines] = line;
        }

        //calculate x
        var x = 0;
        var anchor = "";
        switch (anchorWidth) {
            case TextAnchorWidth.start:
                anchor = 'start';
                break;
            case TextAnchorWidth.middle:
                x +=  (width / 2);
                anchor = 'middle';
                break;
            case TextAnchorWidth.end:
                x += width;
                anchor = 'end';
                break;
        }

        //calculate y
        var y = 0;
        var factorY:number = 0;
        switch (anchorHeight) {
            case TextAnchorHeight.top:
                y += sizeHeight;
                factorY = sizeHeight;
                break;
            case TextAnchorHeight.middle:
                y += ((height - (lines.length  * sizeHeight)) / 2) + (sizeHeight / 2);
                factorY = sizeHeight;
                break;
            case TextAnchorHeight.bottom:
                y +=  height;
                factorY = sizeHeight * -1;
                lines = lines.reverse();
                break;
        }

        //generate tspan
        elementDOM.nativeElement.innerHTML = "";
        for(var i:number=0; i < lines.length;i++){
            elementDOM.nativeElement.innerHTML += '<tspan x="' + x + '" y="' + y + '" text-anchor="'+ anchor +'">' + lines[i] + '</tspan>';
            y += factorY;
        }
    }
};
