import { Component, OnInit } from '@angular/core';
import { Project } from 'projects/btlibrary/src/lib/data/Project';
import { ProjectService } from 'projects/btlibrary/src/lib/services/project.service';
import { ViewClientComponent } from 'projects/btlibrary/src/lib/components/view-client/view-client.component';
import { MessagingService } from 'projects/btlibrary/src/lib/services/messaging.service';

@Component({
  selector: 'bt-mission-subtensions',
  templateUrl: './mission-subtensions.component.html',
  styleUrls: ['./mission-subtensions.component.css']
})
export class MissionSubtensionsComponent implements OnInit {
  project: Project;

  constructor(
    private projectService: ProjectService,
    private messagingService: MessagingService
  ) { }

  ngOnInit() {
    this.getProject();
  }

  getProject(): void {
    this.projectService.getProject()
        .subscribe(project => {
          this.project = project;
          this.ToMissions();
        });
  }

  ToMissions() {
    this.messagingService.SendMessage('ToMissions');
  }
}
