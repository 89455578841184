import { Component, OnInit } from '@angular/core';
import * as Enums from '../../class/application/enums';
import { Dimension } from '../../class/application/dimension';
import { ValueCreationInfo } from './value-creation.animation.data';

@Component({
  selector: '[bt-value-creation]',
  templateUrl: './value-creation.component.html',
  styleUrls: ['./value-creation.component.css'],
  animations: [
        ValueCreationInfo.Rectangle,
        ValueCreationInfo.TensionNameTension,
        ValueCreationInfo.TensionNameMission,
        ValueCreationInfo.TensionNameEdition,
        ValueCreationInfo.CircleExternal,
        ValueCreationInfo.CircleInternal,
        ValueCreationInfo.CircleExternalFull,
        ValueCreationInfo.CircleInternalFull,
        ValueCreationInfo.TensionNameExternal,
        ValueCreationInfo.TensionNameInternal,
    ]
})

export class ValueCreationComponent extends Dimension implements OnInit {

    constructor() {
        super(ValueCreationInfo, Enums.Key.ValueCreation);
    }
    ngOnInit() {
    }
}
