import { trigger, state, style, animate, transition, keyframes, animation } from "@angular/animations";
import { String } from '../../class/platform/string';

export let ViewClient = trigger('animationViewClient', [
    state('ShowBlue', style({ fill:'rgb(56,96,212)' })),
    state('ShowWhite', style({ 'background-color': 'white' })),
]);

export let Logo = trigger('animationLogo', [
    state('FullHidden', style({ opacity: 0 , transform: 'scale(1)' , display:'none' })),
    transition('* => FullHidden', [style({ opacity: 1, } ), animate('1000ms')]),

    state('Show', style({ opacity: 1, transform: 'scale(1)' , display:'block' })),
    transition('* => Show', [style({ opacity: 0, }), animate('1000ms')]),

    state('MainProblem', style({ opacity: 0, transform: 'translateX(1500px) translateY(130px) scale(0.4, 0.85)', display: 'none' })),
    transition('Show => MainProblem', [animate('1500ms')]),
]);

export let MainProblem = trigger('animationMainProblem', [
    state('FullHidden', style({ display: 'none', opacity: 0 })),
    transition('Show => FullHidden', [animate('1000ms')]),

    state('Hidden', style({ display: 'none', opacity: 0 })),
    transition('* => Hidden', [animate('1000ms')]),

    state('Show', style({ display: 'block', opacity: 1 })),
    transition('* =>Show', animation([
        style({ opacity: 0, display: 'block', transform: 'translateX(700px) translateY(500px) scale(0.5)' }),
        animate('1500ms'),
        style({ opacity: 0, }),
    ])),
]);

const Coord = 'translateX({0}px) translateY({1}px)';
const offsetAxis = 10;

export let DimensionResources = trigger('animationDimensionResources', [
    state('Initial', style({ transform : String.Format(Coord, 0, 0) })),
    state('ToCornerFromValueCreationMission',  style({ transform : String.Format(Coord,
        -(1500  - offsetAxis) - 1000 + 350,
        2500 - (150 - offsetAxis)  - 350 - 1000 - (offsetAxis * 2)
        ) })),
    state('ToCornerFromLeadershipMission',  style({ transform : String.Format(Coord,
        -(1500 - offsetAxis) - 1000 + 350,
        -(150 - offsetAxis)  - 1000 + 350
        ) })),
    state('ToCornerFromSelfLeadershipMission',  style({ transform : String.Format(Coord,
        5000 - (1500 - offsetAxis) - 350 - 1000 - (offsetAxis * 2),
        -(150 - offsetAxis)  - 1000 + 350
        ) })),

    transition('ToCornerFromValueCreationMission => Initial', [animate('1000ms')]),
    transition('ToCornerFromLeadershipMission => Initial', [animate('1000ms')]),
    transition('ToCornerFromSelfLeadershipMission => Initial', [animate('1000ms')]),
    transition('* => Initial', [animate('0ms')]),
    transition('* => *', [animate('1000ms')]),
]);

export let DimensionValueCreation = trigger('animationDimensionValueCreation', [
    state('Initial', style({ transform : String.Format(Coord, 0, 0) })),
    state('ToCornerFromResourcesMission',  style({ transform : String.Format(Coord,
        5000 - (2500 - offsetAxis) - 350 - (offsetAxis * 2),
        2500 - (150 - offsetAxis)  - 350 - 1000 - (offsetAxis * 2)
        ) })),
    state('ToCornerFromLeadershipMission',  style({ transform : String.Format(Coord,
        -(1500 - offsetAxis) - 1000 + 350,
        -(150  - offsetAxis)  - 1000 + 350
        ) })),
    state('ToCornerFromSelfLeadershipMission',  style({ transform : String.Format(Coord,
        5000 - (1500 - offsetAxis) - 350 - 1000 - (offsetAxis * 2),
        -(150 - offsetAxis)  - 1000 + 350
        ) })),

    transition('ToCornerFromResourcesMission => Initial', [animate('1000ms')]),
    transition('ToCornerFromLeadershipMission => Initial', [animate('1000ms')]),
    transition('ToCornerFromSelfLeadershipMission => Initial', [animate('1000ms')]),
    transition('* => Initial', [animate('0ms')]),
    transition('* => *', [animate('1000ms')]),
]);

export let DimensionLeadership = trigger('animationDimensionLeadership', [
    state('Initial', style({ transform : String.Format(Coord, 0, 0) })),
    state('ToCornerFromResourcesMission',  style({ transform : String.Format(Coord,
        5000 - (2500 - offsetAxis) - 350 - (offsetAxis * 2),
        2500 - (150 - offsetAxis)  - 350 - 1000 - (offsetAxis * 2)
        ) })),
    state('ToCornerFromValueCreationMission',  style({ transform : String.Format(Coord,
        -(1500 - offsetAxis) -1000 + 350,
        2500 - (150 - offsetAxis)  - 350 - 1000 - (offsetAxis * 2)
        ) })),
    state('ToCornerFromSelfLeadershipMission',  style({ transform : String.Format(Coord,
        5000 - (1500 - offsetAxis) - 350 - 1000 - (offsetAxis * 2),
        -(150 - offsetAxis)  - 1000 + 350
        ) })),

    transition('ToCornerFromResourcesMission => Initial', [animate('1000ms')]),
    transition('ToCornerFromValueCreationMission => Initial', [animate('1000ms')]),
    transition('ToCornerFromSelfLeadershipMission => Initial', [animate('1000ms')]),
    transition('* => Initial', [animate('0ms')]),
    transition('* => *', [animate('1000ms')]),
]);

export let DimensionSelfLeadership = trigger('animationDimensionSelfLeadership', [
    state('Initial', style({ transform : String.Format(Coord, 0, 0) })),
    state('ToCornerFromResourcesMission',  style({ transform : String.Format(Coord,
        5000 - (2500 - offsetAxis) - 350 - (offsetAxis * 2),
        2500 - (150 - offsetAxis)  - 350 - 1000 - (offsetAxis * 2)
        ) })),
    state('ToCornerFromValueCreationMission',  style({ transform : String.Format(Coord,
        -(1500 - offsetAxis) -1000 + 350,
        2500 - (150 - offsetAxis)  - 350 - 1000 - (offsetAxis * 2)
        ) })),
    state('ToCornerFromLeadershipMission',  style({ transform : String.Format(Coord,
        -(1500 - offsetAxis) - 1000 + 350,
        -(150 - offsetAxis)  - 1000 + 350
        ) })),

    transition('ToCornerFromResourcesMission => Initial', [animate('1000ms')]),
    transition('ToCornerFromValueCreationMission => Initial', [animate('1000ms')]),
    transition('ToCornerFromLeadershipMission => Initial', [animate('1000ms')]),
    transition('* => Initial', [animate('0ms')]),
    transition('* => *', [animate('1000ms')]),
]);
