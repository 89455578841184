import { ArrowKind } from './Enums';
import { IArrow } from './IArrow';

export class Initiative implements IArrow {
  public id: number;
  public name: string;
  public direction = 0;
  public knowledge = 0;
  public impact = 0;
  public progress = 0;
  public risk = 0;

  constructor (
    name: string = null,
    direction: number = 0, // 0 left, 1 right
    knowledge: number = 0, // 0 .. 4
    impact: number = 0, // 0 .. 5
    progress: number = 0, // 0 .. 100
    risk: number = 0, // 0 .. 2 red yellow green
  ) {
    this.name = name;
    this.direction = direction;
    this.knowledge = knowledge;
    this.impact = impact;
    this.progress = progress;
    this.risk = risk;
  }

  public get kind(): ArrowKind {  return ArrowKind.Initiative; }
}
