import { Component, OnInit } from '@angular/core';
import * as Enums from '../../class/application/enums';
import { Dimension } from '../../class/application/dimension';
import { SelfLeadershipInfo } from './self-leadership.animation.data';

@Component({
    selector: '[bt-self-leadership]',
    templateUrl: './self-leadership.component.html',
    styleUrls: ['./self-leadership.component.css'],
    animations: [
        SelfLeadershipInfo.Rectangle,
        SelfLeadershipInfo.TensionNameTension,
        SelfLeadershipInfo.TensionNameMission,
        SelfLeadershipInfo.TensionNameEdition,
        SelfLeadershipInfo.CircleExternal,
        SelfLeadershipInfo.CircleInternal,
        SelfLeadershipInfo.CircleExternalFull,
        SelfLeadershipInfo.CircleInternalFull,
        SelfLeadershipInfo.TensionNameExternal,
        SelfLeadershipInfo.TensionNameInternal,
    ]
})
export class SelfLeadershipComponent extends Dimension implements OnInit {

    constructor() {
        super(SelfLeadershipInfo, Enums.Key.SelfLeadership);
    }
    ngOnInit() {
    }

}
