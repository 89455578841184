import { Component, OnInit } from '@angular/core';
import { Project } from 'projects/btlibrary/src/lib/data/Project';
import { ProjectService } from 'projects/btlibrary/src/lib/services/project.service';
import { ViewClientComponent } from 'projects/btlibrary/src/lib/components/view-client/view-client.component';

@Component({
  selector: 'bt-grouping-trends',
  templateUrl: './grouping-trends.component.html',
  styleUrls: ['./grouping-trends.component.css']
})
export class GroupingTrendsComponent implements OnInit {
  project: Project;

  constructor(private projectService: ProjectService) { }

  ngOnInit() {
    this.getProject();
  }

  getProject(): void {
    this.projectService.getProject()
        .subscribe(project => {
          this.project = project;
        });
  }

  public Confirm(dim: number) {
    switch (dim) {
      case 0:
        ViewClientComponent.self.ContextActions.Execute(
          [ { 'Context': 'ViewClient' , 'Action' : 'DefineDimensionToSpecifyDimension' , 'Parameters' : [ 'Resources' ]} ] );
        break;
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
    }
  }
}
