import { ArrowKind } from './Enums';

export class IArrow {
  name: string;
  direction: number;
  knowledge: number;

  impact: number;
  progress: number;
  risk: number;

  readonly kind: ArrowKind; // 0: trend 1: initiative
}
