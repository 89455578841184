import { Component, OnInit } from '@angular/core';
import { Project } from 'projects/btlibrary/src/lib/data/Project';
import { ProjectService } from 'projects/btlibrary/src/lib/services/project.service';
import { MessagingService } from 'projects/btlibrary/src/lib/services/messaging.service';
import { ViewClientComponent } from 'projects/btlibrary/src/lib/components/view-client/view-client.component';

@Component({
  selector: 'bt-project-setup',
  templateUrl: './project-setup.component.html',
  styleUrls: ['./project-setup.component.css']
})
export class ProjectSetupComponent implements OnInit {
  project: Project;

  constructor(
    private projectService: ProjectService
  ) {
  }

  ngOnInit() {
    this.getProject();
  }

  getProject(): void {
    this.projectService.getProject()
      .subscribe(project => {
        this.project = project;
        this.ToLogo();
     });
  }

  public ToLogo() {
    //this.messagingService.SendMessage('ToLogo');
  }

  public SaveProject() {
  }
}
