import { Component, OnInit } from '@angular/core';
import * as Enums from '../../class/application/enums';
import { Dimension } from '../../class/application/dimension';
import { LeadershipInfo } from './leadership.animation-data';

@Component({
    selector: '[bt-leadership]',
    templateUrl: './leadership.component.html',
    styleUrls: ['./leadership.component.css'],
    animations: [
        LeadershipInfo.Rectangle,
        LeadershipInfo.TensionNameTension,
        LeadershipInfo.TensionNameMission,
        LeadershipInfo.TensionNameEdition,
        LeadershipInfo.CircleExternal,
        LeadershipInfo.CircleInternal,
        LeadershipInfo.CircleExternalFull,
        LeadershipInfo.CircleInternalFull,
        LeadershipInfo.TensionNameExternal,
        LeadershipInfo.TensionNameInternal,
    ]
})
export class LeadershipComponent extends Dimension implements OnInit {

    constructor() {
        super(LeadershipInfo, Enums.Key.Leadership);
    }
    ngOnInit() {
    }

}
