import { trigger, state, style, animate, transition, group, AnimationTriggerMetadata } from "@angular/animations";
import { String } from '../../class/platform';

export class AnimationsData {
    // animations
    public Rectangle                                 : AnimationTriggerMetadata;
    public TensionNameTension                        : AnimationTriggerMetadata;
    public TensionNameMission                        : AnimationTriggerMetadata;
    public TensionNameEdition                        : AnimationTriggerMetadata;
    public CircleExternal                            : AnimationTriggerMetadata;
    public CircleInternal                            : AnimationTriggerMetadata;
    public RectaCircleInternalngle                   : AnimationTriggerMetadata;
    public CircleExternalFull                        : AnimationTriggerMetadata;
    public CircleInternalFull                        : AnimationTriggerMetadata;
    public TensionNameExternal                       : AnimationTriggerMetadata;
    public TensionNameInternal                       : AnimationTriggerMetadata;

    // Settings
    public EditionWidth                              : number = 0;
    public EditionHeight                             : number = 0;
    public EditionCircleExternalPosX                 : number = 0;
    public EditionCircleInternalPosX                 : number = 0;
    public EditionCirclePosY                         : number;
    public TensionNameEditionHeight                  : number = 300;

    // protected positions by config and settings
    protected Coord                                  : string = "translateX({0}px) translateY({1}px)";
    protected EmptyCoord                             : string = String.Format(this.Coord, 0, 0);
    protected ScreenWidth                            : number = 5000;
    protected ScreenHeight                           : number = 2500;
    protected FillInitial                            : string = 'rgb(32,57,130)';
    protected OffsetCircleTop                        : number = 200;
    protected OffsetX1                               : number = 350;
    protected OffsetY1                               : number = 350;
    protected OffsetX2                               : number = 350;
    protected OffsetY2                               : number = 350;
    protected OffsetCornerTop                        : number = 200;
    protected OffsetAxis                             : number = 10;

    // positions
    protected PosRectangle_FullHidden                : string = this.EmptyCoord;
    protected PosRectangle_Tension                   : string = this.EmptyCoord;
    protected PosRectangle_Mission                   : string = this.EmptyCoord;
    protected PosRectangle_MissionUnfocus            : string = this.EmptyCoord;
    protected PosRectangle_ToCorner                  : string = this.EmptyCoord;
    protected PosRectangle_Edition                   : string = this.EmptyCoord;

    protected PosTensionNameTension_FullHidden       : string = this.EmptyCoord;
    protected PosTensionNameTension_Tension          : string = this.EmptyCoord;

    protected PosTensionNameMission_FullHidden       : string = this.EmptyCoord;
    protected PosTensionNameMission_Mission          : string = this.EmptyCoord;
    protected PosTensionNameMission_MissionUnfocus   : string = this.EmptyCoord;
    protected PosTensionNameMission_ToCorner         : string = this.EmptyCoord;
    protected PosTensionNameMission_Edition          : string = this.EmptyCoord;

    protected PosTensionNameEdition_FullHidden       : string = this.EmptyCoord;
    protected PosTensionNameEdition_Edition          : string = this.EmptyCoord;
    protected PosTensionNameEdition_ToCorner         : string = this.EmptyCoord;

    protected PosCircleExternal_FullHidden           : string = this.EmptyCoord;
    protected PosCircleExternal_Mission              : string = this.EmptyCoord;
    protected PosCircleExternal_MissionUnfocus       : string = this.EmptyCoord;
    protected PosCircleExternal_ToCorner             : string = this.EmptyCoord;
    protected PosCircleExternal_Edition              : string = this.EmptyCoord;
    protected PosCircleExternal_EditionHidden        : string = this.EmptyCoord;

    protected PosCircleInternal_FullHidden           : string = this.EmptyCoord;
    protected PosCircleInternal_Mission              : string = this.EmptyCoord;
    protected PosCircleInternal_MissionUnfocus       : string = this.EmptyCoord;
    protected PosCircleInternal_ToCorner             : string = this.EmptyCoord;
    protected PosCircleInternal_ToCornerHidden       : string = this.EmptyCoord;

    protected PosCircleExternalFull_FullHidden       : string = this.EmptyCoord;
    protected PosCircleExternalFull_Edition          : string = this.EmptyCoord;
    protected PosCircleExternalFull_ToCornerTop      : string = this.EmptyCoord;

    protected PosCircleInternalFull_FullHidden       : string = this.EmptyCoord;
    protected PosCircleInternalFull_Edition          : string = this.EmptyCoord;
    protected PosCircleInternalFull_ToCornerTop      : string = this.EmptyCoord;

    protected PosTensionNameExternal_FullHidden      : string = this.EmptyCoord;
    protected PosTensionNameExternal_Mission         : string = this.EmptyCoord;
    protected PosTensionNameExternal_MissionUnfocus  : string = this.EmptyCoord;
    protected PosTensionNameExternal_ToCorner        : string = this.EmptyCoord;
    protected PosTensionNameExternal_ToCornerTop     : string = this.EmptyCoord;
    protected PosTensionNameExternal_Edition         : string = this.EmptyCoord;

    protected PosTensionNameInternal_FullHidden      : string = this.EmptyCoord;
    protected PosTensionNameInternal_Mission         : string = this.EmptyCoord;
    protected PosTensionNameInternal_MissionUnfocus  : string = this.EmptyCoord;
    protected PosTensionNameInternal_ToCorner        : string = this.EmptyCoord;
    protected PosTensionNameInternal_Edition         : string = this.EmptyCoord;

    protected posTensionNameMission_Edition          : string = this.EmptyCoord;
    protected posTensionNameEdition_FullHidden       : string = this.EmptyCoord;

    protected posEditionX               : number = 0;
    protected posEditionY               : number = 0;
    protected offsetCircleHeight        : number = 0;
    protected offsetCircleWidth         : number = 0;

    constructor(
        public Id               : string,
        public PosX             : number,
        public PosY             : number,
        public RectangleHeight : number,
        public RectangleWidth   : number,
        public CircleHeight     : number,
        public CircleWidth      : number,
        public Fill             : string,
        public TensionNameHeight: number,
        public TensionNameWidth : number
    ){
        this.offsetCircleHeight                = this.RectangleHeight - this.CircleHeight;
        this.offsetCircleWidth                 = this.RectangleWidth - this.CircleWidth;
        this.posEditionX                       = this.OffsetX1 + (this.OffsetAxis * 2);
        this.posEditionY                       = this.OffsetY1 + (this.OffsetAxis * 2);
        this.EditionWidth                      = this.ScreenWidth  - (this.OffsetX1 + this.OffsetY1 + (this.OffsetAxis * 4));
        this.EditionHeight                     = this.ScreenHeight - (this.OffsetX2 + this.OffsetY2 + (this.OffsetAxis * 4));
        this.posTensionNameMission_Edition     = String.Format(this.Coord, (this.ScreenWidth - this.RectangleWidth) / 2, 0 );
        this.posTensionNameEdition_FullHidden  = String.Format(this.Coord, this.posEditionX, this.posEditionY + 100);
        this.EditionCirclePosY                 = this.posEditionY;
        this.EditionCircleInternalPosX         = this.ScreenWidth - this.OffsetX2 - (this.OffsetAxis * 2) - this.CircleWidth;

        this.SetConfig();
        this.SetAnimations();
    }

    public SetConfig(){}

    //***************************************************************************************************************************************************************************************************************************************************************************************************************************************************************
    public SetAnimations(){
        this.Rectangle = trigger('animationRectangle', [
            state('FullHidden'    , style({ transform: this.PosRectangle_FullHidden     , height: this.RectangleHeight, width: this.RectangleWidth, fill: this.FillInitial, opacity: 0   })),
            state('Tension'       , style({ transform: this.PosRectangle_Tension        , height: this.RectangleHeight, width: this.RectangleWidth, fill: this.Fill       , opacity: 1   })),
            state('Mission'       , style({ transform: this.PosRectangle_Mission        , height: this.RectangleHeight, width: this.RectangleWidth, fill: this.Fill       , opacity: 0.2 })),
            state('MissionUnfocus', style({ transform: this.PosRectangle_MissionUnfocus , height: this.RectangleHeight, width: this.RectangleWidth, fill: this.Fill       , opacity: 0.1 })),
            state('ToCorner'      , style({ transform: this.PosRectangle_ToCorner       , height: this.RectangleHeight, width: this.RectangleWidth, fill: this.Fill       , opacity: 0.2 })),
            state('Edition'       , style({ transform: this.PosRectangle_Edition        , height: this.EditionHeight  , width: this.EditionWidth })),

            transition('* => FullHidden', group([ animate('0ms') ])),
            transition('* => *'         , group([ animate('1000ms') ])),
        ]);

        this.TensionNameTension = trigger('animationTensionNameTension', [
            state('FullHidden', style({ transform: this.PosTensionNameTension_FullHidden, fill: 'white', display: 'none' , opacity: 0 })),
            state('Tension'   , style({ transform: this.PosTensionNameTension_Tension   , fill: 'white', display: 'block', opacity: 1 })),

            transition('Tension => FullHidden', group([ animate('1000ms') ])),
            transition('* => FullHidden'      , group([ animate('0ms') ])),
            transition('* => *'               , group([ animate('1000ms') ])),
        ]);

        this.TensionNameMission = trigger('animationTensionNameMission', [
            state('FullHidden'    , style({ transform: this.PosTensionNameMission_FullHidden    , fill: this.Fill, display: 'none' , opacity: 0   })),
            state('Mission'       , style({ transform: this.PosTensionNameMission_Mission       , fill: this.Fill, display: 'block', opacity: 1   })),
            state('MissionUnfocus', style({ transform: this.PosTensionNameMission_MissionUnfocus, fill: this.Fill, display: 'block', opacity: 0.5 })),
            state('ToCorner'      , style({ transform: this.PosTensionNameMission_ToCorner      , fill: this.Fill, display: 'block', opacity: 1   })),
            state('Edition'       , style({ transform: this.PosTensionNameMission_Edition       , fill: this.Fill, display: 'none' , opacity: 0   })),

            transition('* => FullHidden', group([ animate('0ms') ])),
            transition('* => *'         , group([ animate('1000ms') ])),
        ]);

        this.TensionNameEdition = trigger('animationTensionNameEdition', [
            state('FullHidden', style({ transform: this.PosTensionNameEdition_FullHidden, fill: this.Fill, display: 'none' , opacity: 0 })),
            state('Edition'   , style({ transform: this.PosTensionNameEdition_Edition   , fill: this.Fill, display: 'block', opacity: 1 })),
            state('ToCorner'  , style({ transform: this.PosTensionNameEdition_ToCorner  , fill: this.Fill, display: 'block', opacity: 0 })),

            transition('* => FullHidden', group([ animate('0ms') ])),
            transition('* => *'         , group([ animate('1000ms') ])),
        ]);

        this.CircleExternal = trigger('animationCircleExternal', [
            state('FullHidden'    , style({ transform: this.PosCircleExternal_FullHidden    , height: this.RectangleHeight, width: this.RectangleWidth, display: 'none' , opacity: 0   })),
            state('Mission'       , style({ transform: this.PosCircleExternal_Mission       , height: this.CircleHeight   , width: this.CircleWidth   , display: 'block', opacity: 1   })),
            state('MissionUnfocus', style({ transform: this.PosCircleExternal_MissionUnfocus, height: this.CircleHeight   , width: this.CircleWidth   , display: 'block', opacity: 0.5 })),
            state('ToCorner'      , style({ transform: this.PosCircleExternal_ToCorner      , height: this.CircleHeight   , width: this.CircleWidth   , display: 'block', opacity: 1   })),
            state('Edition'       , style({ transform: this.PosCircleExternal_Edition       , height: this.CircleHeight   , width: this.CircleWidth   , display: 'block', opacity: 1   })),
            state('EditionHidden' , style({ transform: this.PosCircleExternal_EditionHidden , height: this.CircleHeight   , width: this.CircleWidth   , display: 'none' , opacity: 1   })),

            transition('* => FullHidden'   , group([ animate('0ms') ])),
            transition('* => EditionHidden', group([ animate('0ms') ])),
            transition('* => *'            , group([ animate('1000ms') ])),
        ]);

        this.CircleInternal = trigger('animationCircleInternal', [
            state('FullHidden'    , style({ transform: this.PosCircleInternal_FullHidden    , height: this.RectangleHeight, width: this.RectangleWidth, display: 'none' , opacity: 0   })),
            state('Mission'       , style({ transform: this.PosCircleInternal_Mission       , height: this.CircleHeight   , width: this.CircleWidth   , display: 'block', opacity: 1   })),
            state('MissionUnfocus', style({ transform: this.PosCircleInternal_MissionUnfocus, height: this.CircleHeight   , width: this.CircleWidth   , display: 'block' , opacity: 0.5 })),
            state('ToCorner'      , style({ transform: this.PosCircleInternal_ToCorner      , height: this.CircleHeight   , width: this.CircleWidth   , display: 'block', opacity: 1   })),
            state('ToCornerHidden', style({ transform: this.PosCircleInternal_ToCornerHidden, height: this.CircleHeight   , width: this.CircleWidth   , display: 'none' , opacity: 1   })),

            transition('* => FullHidden'    , group([ animate('0ms') ])),
            transition('* => ToCornerHidden', group([ animate('0ms') ])),
            transition('* => *'             , group([ animate('1000ms') ])),
        ]);

        this.CircleExternalFull = trigger('animationCircleExternalFull', [
            state('FullHidden' , style({ transform: this.PosCircleExternalFull_FullHidden , height: (this.CircleHeight * 2), width: this.CircleWidth, display: 'none' , opacity: 1 })),
            state('Edition'    , style({ transform: this.PosCircleExternalFull_Edition    , height: (this.CircleHeight * 2), width: this.CircleWidth, display: 'block', opacity: 1 })),
            state('ToCornerTop', style({ transform: this.PosCircleExternalFull_ToCornerTop, height: (this.CircleHeight * 2), width: this.CircleWidth, display: 'block', opacity: 1 })),

            transition('* => FullHidden' , group([ animate('0ms') ])),
            transition('* => *'          , group([ animate('1000ms') ])),
        ]);

        this.CircleInternalFull = trigger('animationCircleInternalFull', [
            state('FullHidden' , style({ transform: this.PosCircleInternalFull_FullHidden , height: (this.CircleHeight * 2), width: this.CircleWidth, display: 'none' , opacity: 1 })),
            state('Edition'    , style({ transform: this.PosCircleInternalFull_Edition    , height: (this.CircleHeight * 2), width: this.CircleWidth, display: 'block', opacity: 1 })),
            state('ToCornerTop', style({ transform: this.PosCircleInternalFull_ToCornerTop, height: (this.CircleHeight * 2), width: this.CircleWidth, display: 'block', opacity: 1 })),

            transition('* => FullHidden' , group([ animate('0ms') ])),
            transition('* => *'          , group([ animate('1000ms') ])),
        ]);

        this.TensionNameExternal = trigger('animationTensionNameExternal', [
            state('FullHidden'    , style({ transform: this.PosTensionNameExternal_FullHidden    , fill: 'white', display: 'none' , opacity: 0   })),
            state('Mission'       , style({ transform: this.PosTensionNameExternal_Mission       , fill: 'white', display: 'block', opacity: 1   })),
            state('MissionUnfocus', style({ transform: this.PosTensionNameExternal_MissionUnfocus, fill: 'white', display: 'block', opacity: 0.5 })),
            state('ToCorner'      , style({ transform: this.PosTensionNameExternal_ToCorner      , fill: 'white' })),
            state('ToCornerTop'   , style({ transform: this.PosTensionNameExternal_ToCornerTop   , fill: 'white' })),
            state('Edition'       , style({ transform: this.PosTensionNameExternal_Edition       , fill: 'white' })),

            transition('* => FullHidden', group([ animate('0ms') ])),
            transition('* => *'         , group([ animate('1000ms') ])),
        ]);

        this.TensionNameInternal = trigger('animationTensionNameInternal', [
            state('FullHidden'    , style({ transform: this.PosTensionNameInternal_FullHidden    , fill: 'white', display: 'none' , opacity: 0 })),
            state('Mission'       , style({ transform: this.PosTensionNameInternal_Mission       , fill: 'white', display: 'block', opacity: 1 })),
            state('MissionUnfocus', style({ transform: this.PosTensionNameInternal_MissionUnfocus, fill: 'white', display: 'block', opacity: 0.5 })),
            state('ToCorner'      , style({ transform: this.PosTensionNameInternal_ToCorner      , fill: 'white' })),
            state('Edition'       , style({ transform: this.PosTensionNameInternal_Edition       , fill: 'white' })),

            transition('* => FullHidden', group([ animate('0ms') ])),
            transition('* => *'         , group([ animate('1000ms') ])),
        ]);
    }
}
