import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { ProjectSetupComponent } from './project/project-setup/project-setup.component';
import { BTLibraryModule } from '../../../btlibrary/src/public_api';
import { CriticalEventsComponent } from './project/critical-events/critical-events.component';
import { DimensionsComponent } from './dimensions/dimensions/dimensions.component';
import { MissionComponent } from './mission/mission/mission.component';
import { MissionSubtensionsComponent } from './mission/mission-subtensions/mission-subtensions.component';
import { SubtensionComponent } from './mission/subtension/subtension.component';
import { HeaderComponent } from './header/header.component';
import { EventListComponent } from './project/event-list/event-list.component';
import { MainTensionsComponent } from './dimensions/main-tensions/main-tensions.component';
import { TrendDimensionComponent } from './trends/trend-dimension/trend-dimension.component';
import { TrendDetailComponent } from './trends/trend-detail/trend-detail.component';
import { GroupingTrendsComponent } from './grouping/grouping-trends/grouping-trends.component';
import { GroupingDetailComponent } from './grouping/grouping-detail/grouping-detail.component';
import { InitiativeDimensionComponent } from './initiatives/initiative-dimension/initiative-dimension.component';
import { InitiativeDetailComponent } from './initiatives/initiative-detail/initiative-detail.component';

const routes: Routes = [
  { path: 'project-setup', component: ProjectSetupComponent },
  { path: 'critical-events', component: CriticalEventsComponent },
  { path: 'event-list', component: EventListComponent },
  { path: 'dimensions', component: DimensionsComponent },
  { path: 'main-tensions', component: MainTensionsComponent },
  { path: 'mission', component: MissionComponent },
  { path: 'mission-subtensions', component: MissionSubtensionsComponent },
  { path: 'subtension/:id', component: SubtensionComponent },
  { path: 'trend-dimension', component: TrendDimensionComponent },
  { path: 'trend-detail', component: TrendDetailComponent },
  { path: 'grouping-trends', component: GroupingTrendsComponent },
  { path: 'grouping-detail', component: GroupingDetailComponent },
  { path: 'initiative-dimension', component: InitiativeDimensionComponent },
  { path: 'initiative-detail', component: InitiativeDetailComponent },
  { path: '', redirectTo: '/project-setup', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ProjectSetupComponent,
    CriticalEventsComponent,
    EventListComponent,
    DimensionsComponent,
    MainTensionsComponent,
    MissionComponent,
    MissionSubtensionsComponent,
    SubtensionComponent,
    TrendDimensionComponent,
    TrendDetailComponent,
    GroupingTrendsComponent,
    GroupingDetailComponent,
    InitiativeDimensionComponent,
    InitiativeDetailComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BTLibraryModule,
    RouterModule.forRoot(
      routes
    )
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
