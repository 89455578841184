import { Component, OnInit } from '@angular/core';
import { Project } from 'projects/btlibrary/src/lib/data/Project';
import { Event } from 'projects/btlibrary/src/lib/data/Event';
import { ProjectService } from 'projects/btlibrary/src/lib/services/project.service';
import { MessagingService } from 'projects/btlibrary/src/lib/services/messaging.service';
import { ViewClientComponent } from 'projects/btlibrary/src/lib/components/view-client/view-client.component';

@Component({
  selector: 'bt-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit {
  project: Project;
  curEvent: Event;

  constructor(
    private projectService: ProjectService,
    private messagingService: MessagingService
  ) {
  }

  ngOnInit() {
    this.getProject();
  }

  getProject(): void {
    this.projectService.getProject()
        .subscribe(project => {
          this.project = project;
          this.curEvent = this.project.criticalEvents[0];
          this.ToMainProblem();
        });
  }

  ToMainProblem() {
    this.messagingService.SendMessage('ToMainProblem');
  }

  public SelectEvent(id: number) {
    this.curEvent = this.project.criticalEvents[id];
  }
}
