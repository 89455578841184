import { ArrowKind } from './Enums';
import { IArrow } from './IArrow';

export class Trend implements IArrow {
  public id: number;
  public name: string;
  public direction = 0;
  public knowledge = 0;

  constructor (
    name: string = null,
    direction: number = 0, // 0 left, 1 right
    knowledge: number = 0, // 0 .. 4
  ) {
    this.name = name;
    this.direction = direction;
    this.knowledge = knowledge;
  }

  public get impact(): number {  return -1; }
  public get progress(): number {  return -1; }
  public get risk(): number {  return -1; }
  public get kind(): ArrowKind {  return ArrowKind.Trend; }
}
