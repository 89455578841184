export enum TextAnchorWidth {
    start,
    middle,
    end
}

export enum TextAnchorHeight {
    top,
    middle,
    bottom
}

