import { Tension } from './Tension';

export class TensionModel extends Tension {

  public static recalculateTension(t: Tension) {
    t.yesterday = this.average( t.subtensions.map((s) => +s.yesterday));
    t.today = this.average( t.subtensions.map((s) => +s.today));
    t.tomorrow = this.average( t.subtensions.map((s) => +s.tomorrow));
  }

  private static average(array: Array<number>): number {
    const average = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;
    return average( array );
  }
}
