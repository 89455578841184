import { Component, Input } from '@angular/core';
import { IArrow } from '../../data/IArrow';

@Component({
  selector: '[bt-arrow]',
  templateUrl: './arrow.component.html',
  styleUrls: ['./arrow.component.css']
})
export class ArrowComponent {
  @Input() arrow: IArrow;

  constructor() { }
}

