import { Component, Input } from '@angular/core';
import { Trend } from '../../data/Trend';

@Component({
  selector: 'bt-trend',
  templateUrl: './trend.component.html',
  styleUrls: ['./trend.component.css']
})
export class TrendComponent {
  @Input() trend: Trend;

  constructor() { }
}

