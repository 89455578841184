import { Component, OnInit } from '@angular/core';
import * as Enums from '../../class/application/enums';
import { Dimension } from '../../class/application/dimension';
import { ResourcesInfo } from './resources-animation.data';

@Component({
    selector: '[bt-resources]',
    templateUrl: './resources.component.html',
    styleUrls: ['./resources.component.css'],
    animations: [
        ResourcesInfo.Rectangle,
        ResourcesInfo.TensionNameTension,
        ResourcesInfo.TensionNameMission,
        ResourcesInfo.TensionNameEdition,
        ResourcesInfo.CircleExternal,
        ResourcesInfo.CircleInternal,
        ResourcesInfo.CircleExternalFull,
        ResourcesInfo.CircleInternalFull,
        ResourcesInfo.TensionNameExternal,
        ResourcesInfo.TensionNameInternal,
    ]
})
export class ResourcesComponent extends Dimension implements OnInit {

    constructor() {
        super(ResourcesInfo, Enums.Key.Resources);
    }
    ngOnInit() {
    }
}
