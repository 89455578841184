export enum TensionType {
    Resources = 0,
    ValueCreation = 1,
    SelftLeadership = 2,
    Leadership = 3,
}

export let TensionTypeString: { [id: number]: string; } = {
    0 : 'Resources',
    1 : 'ValueCreation',
    2 : 'SelfLeadership',
    3 : 'Leadership',
}

export enum Key {
    Logo = 'logo',
    ViewClient = 'viewClient',
    MainProblem = 'mainProblem',
    MainProblemDescription = 'description',
    MainTensions = 'mainTensions',

    Resources = 'Resources',
    ValueCreation = 'ValueCreation',
    Leadership = 'Leadership',
    SelfLeadership = 'SelfLeadership',

    DimensionResources = "DimensionResources",
    DimensionValueCreation = "DimensionValueCreation",
    DimensionLeadership = "DimensionLeadership",
    DimensionSelfLeadership = "DimensionSelfLeadership",

    ResourcesState = 'ResourcesState',
    ValueCreationState = 'ValueCreationState',
    LeadershipState = 'LeadershipState',
    SelfLeadershipState = 'SelfLeadershipState',

    Rectangle = "Rectangle",
    TensionNameTension = "TensionNameTension",
    TensionNameMission = "TensionNameMission",
    TensionNameEdition = "TensionNameEdition",
    CircleExternal = "CircleExternal",
    CircleInternal = "CircleInternal",
    CircleExternalFull = "CircleExternalFull",
    CircleInternalFull = "CircleInternalFull",
    TensionNameExternal = 'TensionNameExternal',
    TensionNameInternal = 'TensionNameInternal',


    //**************************************************************** */
    ResourcesMission = 'resourcesMission',
    ValueCreationMission = 'valueCreationMission',
    LeadershipMission = 'leadershipMission',
    SelfLeadershipMission = 'selfLeadershipMission',

    ResourcesEdition = 'resourcesEdition',
    ValueCreationEdition = 'valueCreationEdition',
    LeadershipEdition = 'leadershipEdition',
    SelfLeadershipEdition = 'selfLeadershipEdition',

    ResourcesCircleExternal = 'resourcesCircleExternal',
    ResourcesCircleInternal = 'resourcesCircleInternal',
    ResourcesCircleExternalFull = 'resourcesCircleExternalFull',
    ResourcesCircleInternalFull = 'resourcesCircleInternalFull',

    ValueCreationCircleExternal = 'valueCreationCircleExternal',
    ValueCreationCircleInternal = 'valueCreationCircleInternal',
    ValueCreationCircleExternalFull = 'valueCreationCircleExternalFull',
    ValueCreationCircleInternalFull = 'valueCreationCircleInternalFull',

    LeadershipCircleExternal = 'leadershipCircleExternal',
    LeadershipCircleInternal = 'leadershipCircleInternal',
    LeadershipCircleExternalFull = 'leadershipCircleExternalFull',
    LeadershipCircleInternalFull = 'leadershipCircleInternalFull',

    SelfLeadershipCircleExternal = 'selfLeadershipCircleExternal',
    SelfLeadershipCircleInternal = 'selfLeadershipCircleInternal',
    SelfLeadershipCircleExternalFull = 'selfLeadershipCircleExternalFull',
    SelfLeadershipCircleInternalFull = 'selfLeadershipCircleInternalFull',

    ResourcesLabelCircleExternal = 'resourcesLabelCircleExternal',
    ResourcesLabelCircleInternal = 'resourcesLabelCircleInternal',
    ValueCreationLabelCircleExternal = 'valueCreationLabelCircleExternal',
    ValueCreationLabelCircleInternal = 'valueCreationLabelCircleInternal',
    LeadershipLabelCircleExternal = 'leadershipLabelCircleExternal',
    LeadershipLabelCircleInternal = 'leadershipLabelCircleInternal',
    SelfLeadershipLabelCircleExternal = 'selfLeadershipLabelCircleExternal',
    SelfLeadershipLabelCircleInternal = 'selfLeadershipLabelCircleInternal',

    RectangleState = 'rectangleState',
    CircleState = 'CircleState',

}

export enum StateValue {
    Logo = 'Logo',
    MainProblem = 'MainProblem',
    Tensions = 'Tensions',
    Missions = 'Missions',
    Mission = 'Mission',
    Edition = 'Edition',
    Tension = 'Tension',
    ShowBlue = 'ShowBlue',
    ShowWhite = 'ShowWhite',
    Show = 'Show',
    HiddenDescription = 'HiddenDescription',
    Hidden = 'Hidden',
    Initial = 'Initial',
    FullHidden = 'FullHidden',
    MissionHidden = 'MissionHidden',
    MissionUnfocus = 'MissionUnfocus',
    EditionHidden = 'EditionHidden',

    ResourcesMission = 'ResourcesMission',
    ValueCreationMission = 'ValueCreationMission',
    LeadershipMission  = 'LeadershipMission',
    SelfLeadershipMission = 'SelfLeadershipMission',

    ResourcesEdition = 'ResourcesEdition',
    ValueCreationEdition = 'ValueCreationEdition',
    LeadershipEdition  = 'LeadershipEdition',
    SelfLeadershipEdition = 'SelfLeadershipEdition',

    ToCorner = "ToCorner",
    ToCornerHidden = "ToCornerHidden",

    ToCornerFromResourcesMission ='ToCornerFromResourcesMission',
    ToCornerFromValueCreationMission = 'ToCornerFromValueCreationMission',
    ToCornerFromLeadershipMission ='ToCornerFromLeadershipMission',
    ToCornerFromSelfLeadershipMission = 'ToCornerFromSelfLeadershipMission',

    ToCornerFromResourcesEdition ='ToCornerFromResourcesEdition',
    ToCornerFromValueCreationEdition = 'ToCornerFromValueCreationEdition',
    ToCornerFromLeadershipEdition ='ToCornerFromLeadershipEdition',
    ToCornerFromSelfLeadershipEdition = 'ToCornerFromSelfLeadershipEdition',

    ToCornerFromResourcesMissionHidden = 'ToCornerFromResourcesMissionHidden',
    ToCornerFromValueCreationMissionHidden = 'ToCornerFromValueCreationMissionHidden',
    ToCornerFromLeadershipMissionHidden = "ToCornerFromLeadershipMissionHidden",
    ToCornerFromSelfLeadershipMissionHidden = "ToCornerFromSelfLeadershipMissionHidden",

    ToCornerTop = 'ToCornerTop',
    ToCornerEdition = 'ToCornerEdition',
}
