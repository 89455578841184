import { Component, OnInit } from '@angular/core';
import { Project } from 'projects/btlibrary/src/lib/data/Project';
import { ProjectService } from 'projects/btlibrary/src/lib/services/project.service';
import { ViewClientComponent } from 'projects/btlibrary/src/lib/components/view-client/view-client.component';
import { MessagingService } from 'projects/btlibrary/src/lib/services/messaging.service';

@Component({
  selector: 'bt-main-tensions',
  templateUrl: './main-tensions.component.html',
  styleUrls: ['./main-tensions.component.css']
})
export class MainTensionsComponent implements OnInit {
  project: Project;

  constructor(
    private projectService: ProjectService,
    private messagingService: MessagingService
  ) {
  }

  ngOnInit() {
    this.getProject();
  }

  getProject(): void {
    this.projectService.getProject()
        .subscribe(project => {
          this.project = project;
          this.ToMissions();
        });
  }

  public Confirm(dim: number) {
    switch (dim) {
      case 0:
        this.messagingService.SendMessage('ToMission', [ 'Resources' ]);
        break;
      case 1:
        this.messagingService.SendMessage('ToMission' , [ 'ValueCreation' ]);
        break;
      case 2:
        this.messagingService.SendMessage('ToMission' , [ 'SelfLeadership' ]);
        break;
      case 3:
        this.messagingService.SendMessage('ToMission' , [ 'Leadership' ]);
        break;
    }
  }

  ToMissions() {
    this.messagingService.SendMessage('ToMissions');
  }
}
