import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Project } from '../data/Project';
import { Mock } from './mock-project';
import { environment } from '../../../../BtModeration/src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  readonly endpoint = environment.serverUrl + '/projects/';

  readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };

  constructor(private http: HttpClient) { }

  public getProject(id: number = 1): Observable<Project> {
    if (!environment.production) {
      return of(Mock.Project);
    }

    return this.http.get(this.endpoint + id).pipe(
      tap(_ => console.log(`get project id=${id}`)),
      catchError(this.handleError<Project>('getProject', Mock.Project)),
      map(this.extractData)
    );
  }

  public updateProject (project: Project): Observable<Project> {
    if (!environment.production) {
      Mock.Project = project;
      return of(Mock.Project);
    }

    return this.http.put(this.endpoint + project.id, JSON.stringify(project), this.httpOptions).pipe(
       tap(_ => console.log(`updated project w/ id=${project.id}`)),
       catchError(this.handleError<any>('updateProject')),
       map(this.extractData)
     );
   }

  public addProject (project: Project): Observable<Project> {
    if (!environment.production) {
      Mock.Project = project;
      return of(Mock.Project);
    }

    return this.http.post(this.endpoint, JSON.stringify(project), this.httpOptions).pipe(
      tap((p: Project) => console.log(`insertng project w/ id=${p.id}`)),
      catchError(this.handleError<any>('addProject')),
      map(this.extractData),
    );
  }

  private deleteProject (id: number): Observable<Project> {
    if (!environment.production) {
      Mock.Project = null;
      return of(Mock.Project);
    }

    return this.http.delete(this.endpoint + id, this.httpOptions).pipe(
      tap(_ => console.log(`deleting project w/ id=${id}`)),
      catchError(this.handleError<any>('deleteProject'))
    );
  }

  private extractData(res: Response): Project {
    return <Project>(res as Object);
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning the given result.
      return of(result as T);
    };
  }
}
