import { Component, OnInit } from '@angular/core';
import { Project } from '../../../../../btlibrary/src/lib/data/Project';
import { Tension } from '../../../../../btlibrary/src/lib/data/Tension';
import { ProjectService } from '../../../../../btlibrary/src/lib/services/project.service';
import { ViewClientComponent } from '../../../../../btlibrary/src/lib/components/view-client/view-client.component';
import { TensionModel } from '../../../../../btlibrary/src/lib/data/TensionModel';
import { Trend } from 'projects/btlibrary/src/lib/data/Trend';
import { TrendComponent } from '../../../../../btlibrary/src/lib/controls/trend/trend.component';

@Component({
  selector: 'bt-trend-detail',
  templateUrl: './trend-detail.component.html',
  styleUrls: ['./trend-detail.component.css']
})
export class TrendDetailComponent implements OnInit {
  project: Project;
  curTension: Tension;
  curTrend: Trend;

  constructor(private projectService: ProjectService) { }

  ngOnInit() {
    this.getProject();
  }

  getProject(): void {
    this.projectService.getProject()
        .subscribe(project => {
          this.project = project;
          this.curTension = this.project.tensions[1];
          this.curTrend = this.curTension.trends[0];
          TensionModel.recalculateTension(this.curTension);
        });
  }

  public UpdateTrend() {
  }

  public Confirm(dim: number) {
    switch (dim) {
      case 0:
        // this.messagingService.SendMessage('DefineDimensionToSpecifyDimension', [ 'Resources' ]);
        break;
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
    }
  }
}
