import { trigger, state, style, animate, transition, group } from "@angular/animations";

export let Resources = trigger('animationResources', [
    state('FullHidden' , style({ transform: 'translateX(1485px) translateY(135px)', fill: 'white', display: 'none' , opacity: 0 })),
    state('Tension'    , style({ transform: 'translateX(1485px) translateY(135px)', fill: 'white', display: 'block', opacity: 1 })),

    transition('Tension => FullHidden' , group([ animate('1000ms') ])),
    transition('* => FullHidden'       , group([ animate('0ms') ])),
    transition('* => *'                , group([ animate('1000ms') ])),
]);

export let ValueCreation = trigger('animationValueCreation', [
    state('FullHidden' , style({ transform: 'translateX(2515px) translateY(135px)', fill: 'white', display: 'none' , opacity: 0 })),
    state('Tension'    , style({ transform: 'translateX(2515px) translateY(135px)', fill: 'white', display: 'block', opacity: 1 })),

    transition('Tension => FullHidden' , group([ animate('1000ms') ])),
    transition('* => FullHidden'       , group([ animate('0ms') ])),
    transition('* => *'                , group([ animate('1000ms') ])),
]);

export let Leadership = trigger('animationLeadership', [
    state('FullHidden' , style({ transform: 'translateX(2515px) translateY(1165px)', fill: 'white', display: 'none' , opacity: 0 })),
    state('Tension'    , style({ transform: 'translateX(2515px) translateY(1165px)', fill: 'white', display: 'block', opacity: 1 })),

    transition('Tension => FullHidden' , group([ animate('1000ms') ])),
    transition('* => FullHidden'       , group([ animate('0ms') ])),
    transition('* => *'                , group([ animate('1000ms') ])),
]);

export let SelfLeadership = trigger('animationSelfLeadership', [
    state('FullHidden' , style({ transform: 'translateX(1485px) translateY(1165px)', fill: 'white', display: 'none' , opacity: 0 })),
    state('Tension'    , style({ transform: 'translateX(1485px) translateY(1165px)', fill: 'white', display: 'block', opacity: 1 })),

    transition('Tension => FullHidden' , group([ animate('1000ms') ])),
    transition('* => FullHidden'       , group([ animate('0ms') ])),
    transition('* => *'                , group([ animate('1000ms') ])),
]);

export let ResourcesMission = trigger('animationResourcesMission', [
    state('FullHidden'                        , style({ transform: 'translateX(1485px) translateY(135px)'  , fill: 'rgb(56,95,214)', opacity: 0, display: 'none'})),
    state('Mission'                           , style({ transform: 'translateX(1485px) translateY(135px)'  , fill: 'rgb(56,95,214)', opacity: 1, display: 'block' })),
    state('MissionUnfocus'                    , style({ transform: 'translateX(1485px) translateY(135px)'  , fill: 'rgb(56,95,214)', opacity: 0.5 })),
    state('ToCornerFromResourcesMission'      , style({ transform: 'translateX(3580px) translateY(810px)'  , fill: 'rgb(56,95,214)', opacity: 1 })),
    state('ToCornerFromValueCreationMission'  , style({ transform: 'translateX(-680px) translateY(810px)'  , fill: 'rgb(56,95,214)', opacity: 0, display: 'none' })),
    state('ToCornerFromLeadershipMission'     , style({ transform: 'translateX(-680px) translateY(-610px)' , fill: 'rgb(56,95,214)', opacity: 0, display: 'none' })),
    state('ToCornerFromSelfLeadershipMission' , style({ transform: 'translateX(3580px) translateY(-610px)' , fill: 'rgb(56,95,214)', opacity: 0, display: 'none' })),
    state('Edition'                           , style({ transform: 'translateX(2000px) translateY(-150px)' , fill: 'rgb(56,95,214)', opacity: 0, display: 'none' })),

    transition('* => FullHidden'       , group([ animate('0ms') ])),
    transition('Edition => Mission'    , group([ style({ transform: 'translateX(3580px) translateY(810px)' , fill: 'rgb(56,95,214)', opacity: 1 }), animate('1000ms') ])),
    transition('* => *'                , group([ animate('1000ms') ])),
]);

export let ValueCreationMission = trigger('animationValueCreationMission', [
    state('FullHidden'                        , style({ transform: 'translateX(2515px) translateY(135px)'  , fill: 'rgb(16,28,65)', opacity: 0 , display: 'none'})),
    state('Mission'                           , style({ transform: 'translateX(2515px) translateY(135px)'  , fill: 'rgb(16,28,65)', opacity: 1 , display: 'block' })),
    state('MissionUnfocus'                    , style({ transform: 'translateX(2515px) translateY(135px)'  , fill: 'rgb(16,28,65)', opacity: 0.5 })),
    state('ToCornerFromResourcesMission'      , style({ transform: 'translateX(4580px) translateY(810px)'  , fill: 'rgb(16,28,65)', opacity: 0 , display: 'none' })),
    state('ToCornerFromValueCreationMission'  , style({ transform: 'translateX(380px) translateY(810px)'   , fill: 'rgb(16,28,65)', opacity: 1 })),
    state('ToCornerFromLeadershipMission'     , style({ transform: 'translateX(380px) translateY(-610px)'  , fill: 'rgb(16,28,65)', opacity: 0 , display: 'none' })),
    state('ToCornerFromSelfLeadershipMission' , style({ transform: 'translateX(4580px) translateY(-610px)' , fill: 'rgb(16,28,65)', opacity: 0, display: 'none' })),
    state('Edition'                           , style({ transform: 'translateX(2000px) translateY(-150px)' , fill: 'rgb(16,28,65)', opacity: 0 , display: 'none' })),

    transition('* => FullHidden'       , group([ animate('0ms') ])),
    transition('Edition => Mission'    , group([ style({ transform: 'translateX(380px) translateY(810px)'  , fill: 'rgb(16,28,65)', opacity: 1 }), animate('1000ms') ])),
    transition('* => *'                , group([ animate('1000ms') ])),
]);

export let LeadershipMission = trigger('animationLeadershipMission', [
    state('FullHidden'                        , style({ transform: 'translateX(2515px) translateY(1165px)' , fill: 'rgb(29,52,118)', opacity: 0, display: 'none'})),
    state('Mission'                           , style({ transform: 'translateX(2515px) translateY(1165px)' , fill: 'rgb(29,52,118)', opacity: 1, display: 'block' })),
    state('MissionUnfocus'                    , style({ transform: 'translateX(2515px) translateY(1165px)' , fill: 'rgb(29,52,118)', opacity: 0.5 })),
    state('ToCornerFromResourcesMission'      , style({ transform: 'translateX(4580px) translateY(1800px)' , fill: 'rgb(29,52,118)', opacity: 0, display: 'none' })),
    state('ToCornerFromValueCreationMission'  , style({ transform: 'translateX(380px) translateY(1800px)'  , fill: 'rgb(29,52,118)', opacity: 0, display: 'none' })),
    state('ToCornerFromLeadershipMission'     , style({ transform: 'translateX(380px) translateY(370px)'   , fill: 'rgb(29,52,118)', opacity: 1 })),
    state('ToCornerFromSelfLeadershipMission' , style({ transform: 'translateX(4580px) translateY(370px)'  , fill: 'rgb(29,52,118)', opacity: 0, display: 'none' })),
    state('Edition'                           , style({ transform: 'translateX(2000px) translateY(-50px)'  , fill: 'rgb(29,52,118)', opacity: 0, display: 'none' })),

    transition('* => FullHidden'       , group([ animate('0ms') ])),
    transition('Edition => Mission'    , group([ style({ transform: 'translateX(380px) translateY(370px)'  , fill: 'rgb(29,52,118)', opacity: 1 }), animate('1000ms') ])),
    transition('* => *'                , group([ animate('1000ms') ])),
]);

export let SelfLeadershipMission = trigger('animationSelfLeadershipMission', [
    state('MainProblem'                       , style({ transform: 'translateX(1485px) translateY(1165px)' , fill: 'rgb(41,74,168)', opacity: 0, display: 'none'})),
    state('FullHidden'                        , style({ transform: 'translateX(1485px) translateY(1165px)' , fill: 'rgb(41,74,168)', opacity: 0, display: 'none'})),
    state('Mission'                           , style({ transform: 'translateX(1485px) translateY(1165px)' , fill: 'rgb(41,74,168)', opacity: 1, display: 'block' })),
    state('MissionUnfocus'                    , style({ transform: 'translateX(1485px) translateY(1165px)' , fill: 'rgb(41,74,168)', opacity: 0.5 })),
    state('ToCornerFromResourcesMission'      , style({ transform: 'translateX(3580px) translateY(1800px)' , fill: 'rgb(41,74,168)', opacity: 0, display: 'none' })),
    state('ToCornerFromValueCreationMission'  , style({ transform: 'translateX(-680px) translateY(1800px)' , fill: 'rgb(41,74,168)', opacity: 0, display: 'none' })),
    state('ToCornerFromLeadershipMission'     , style({ transform: 'translateX(-680px) translateY(370px)'  , fill: 'rgb(41,74,168)', opacity: 0, display: 'none' })),
    state('ToCornerFromSelfLeadershipMission' , style({ transform: 'translateX(3580px) translateY(370px)'  , fill: 'rgb(41,74,168)', opacity: 1 })),
    state('Edition'                           , style({ transform: 'translateX(2000px) translateY(-50px)'  , fill: 'rgb(41,74,168)', opacity: 0, display: 'none' })),

    transition('* => FullHidden'       , group([ animate('0ms') ])),
    transition('Edition => Mission'    , group([ style({ transform: 'translateX(3580px) translateY(370px)', fill: 'rgb(41,74,168)', opacity: 1 }), animate('1000ms') ])),
    transition('* => *'                , group([ animate('1000ms') ])),
]);

export let ResourcesEdition = trigger('animationResourcesEdition', [
    state('FullHidden'                   , style({ transform: 'translateX(380px) translateY(100px)'              , display: 'none'  , opacity: 0 })),
    state('MainProblem'                  , style({ transform: 'translateX(380px) translateY(100px)'              , display: 'none'  , opacity: 0 })),
    state('Edition'                      , style({ transform: 'translateX(380px) translateY(100px)'              , display: 'block' , opacity: 1 })),
    state('ToCornerFromResourcesEdition' , style({ transform: 'translateX(3550px) translateY(1250px) scale(0.2)' , display: 'none'  , opacity: 0 })),

    transition('* => FullHidden' , group([ animate('0ms') ])),
    transition('* => *'          , group([ animate('1000ms') ])),
]);

export let ValueCreationEdition = trigger('animationValueCreationEdition', [
    state('FullHidden'                       , style({ transform: 'translateX(380px) translateY(100px)'             , display: 'none'  , opacity: 0 })),
    state('Edition'                          , style({ transform: 'translateX(380px) translateY(100px)'             , display: 'block' , opacity: 1 })),
    state('ToCornerFromValueCreationEdition' , style({ transform: 'translateX(150px) translateY(1250px) scale(0.2)' , display: 'none'  , opacity: 0 })),

    transition('* => FullHidden', group([ animate('0ms') ])),
    transition('* => *'         , group([ animate('1000ms') ])),
]);

export let LeadershipEdition = trigger('animationLeadershipEdition', [
    state('FullHidden'                    , style({ transform: 'translateX(380px) translateY(300px)'            , display: 'none'  , opacity: 0 })),
    state('Edition'                       , style({ transform: 'translateX(380px) translateY(300px)'            , display: 'block' , opacity: 1 })),
    state('ToCornerFromLeadershipEdition' , style({ transform: 'translateX(150px) translateY(850px) scale(0.2)' , display: 'none'  , opacity: 0 })),

    transition('* => FullHidden' , group([ animate('0ms') ])),
    transition('* => *'          , group([ animate('1000ms') ])),
]);

export let SelfLeadershipEdition = trigger('animationSelfLeadershipEdition', [
    state('FullHidden'                        , style({ transform: 'translateX(380px) translateY(300px)'             , display: 'none'  , opacity: 0 })),
    state('Edition'                           , style({ transform: 'translateX(380px) translateY(300px)'             , display: 'block' , opacity: 1 })),
    state('ToCornerFromSelfLeadershipEdition' , style({ transform: 'translateX(3550px) translateY(850px) scale(0.2)' , display: 'none' , opacity: 0 })),

    transition('* => FullHidden' , group([ animate('0ms') ])),
    transition('* => *'          , group([ animate('1000ms') ])),
]);
