import { AnimationsData } from "../../class/application/animations";
import * as Enums from '../../class/application/enums';
import { String } from '../../class/platform/string';

class ValueCreationData extends AnimationsData{
    public SetConfig(){
        let posToCornerX                 = this.OffsetX1 + (this.OffsetAxis * 2);
        let posToCornerY                 = this.ScreenHeight - this.OffsetY1 - (this.OffsetAxis * 2) - this.RectangleHeight;

        // Rectangle ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        this.PosRectangle_FullHidden     = String.Format(this.Coord, this.PosX, this.PosY);
        this.PosRectangle_Tension        = String.Format(this.Coord, this.PosX + this.OffsetAxis, this.PosY - this.OffsetAxis);
        this.PosRectangle_Mission        = this.PosRectangle_Tension;
        this.PosRectangle_MissionUnfocus = this.PosRectangle_Tension;
        this.PosRectangle_ToCorner       = String.Format(this.Coord, posToCornerX, posToCornerY);
        this.PosRectangle_Edition        = String.Format(this.Coord, this.posEditionX, this.posEditionY);

        // Tension Name ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        // Tension
        this.PosTensionNameTension_FullHidden     = this.PosRectangle_FullHidden;
        this.PosTensionNameTension_Tension       = this.PosRectangle_FullHidden;
     // Mission
        this.PosTensionNameMission_FullHidden     = this.PosRectangle_Tension;
        this.PosTensionNameMission_Mission        = this.PosRectangle_Tension;
        this.PosTensionNameMission_MissionUnfocus = this.PosRectangle_Tension;
        this.PosTensionNameMission_ToCorner       = this.PosRectangle_ToCorner;
        this.PosTensionNameMission_Edition        = this.posTensionNameMission_Edition;

        // Edition
        this.PosTensionNameEdition_FullHidden    = this.posTensionNameEdition_FullHidden;
        this.PosTensionNameEdition_Edition       = this.PosTensionNameEdition_FullHidden;
        this.PosTensionNameEdition_ToCorner      = String.Format(this.Coord, posToCornerX + this.offsetCircleWidth - 150, posToCornerY + this.offsetCircleHeight - 150) + 'scale(0.2)';

        // Circles ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        // External
        this.PosCircleExternal_FullHidden     = this.PosRectangle_FullHidden;
        this.PosCircleExternal_Mission        = String.Format(this.Coord, this.PosX + this.OffsetAxis + this.offsetCircleWidth, this.PosY - this.OffsetAxis);
        this.PosCircleExternal_MissionUnfocus = this.PosCircleExternal_Mission;
        this.PosCircleExternal_ToCorner       = String.Format(this.Coord, posToCornerX + this.offsetCircleWidth, posToCornerY);
        this.PosCircleExternal_Edition        = String.Format(this.Coord, this.posEditionX + this.EditionWidth - this.CircleWidth, this.posEditionY);
        this.PosCircleExternal_EditionHidden  = this.PosCircleExternal_Edition;

        // Internal
        this.PosCircleInternal_FullHidden     = this.PosCircleExternal_FullHidden;
        this.PosCircleInternal_Mission        = String.Format(this.Coord, this.PosX + this.OffsetAxis, this.PosY - this.OffsetAxis + this.offsetCircleHeight);
        this.PosCircleInternal_MissionUnfocus = this.PosCircleInternal_Mission;
        this.PosCircleInternal_ToCorner       = String.Format(this.Coord, posToCornerX, posToCornerY + this.offsetCircleHeight);
        this.PosCircleInternal_ToCornerHidden = this.PosCircleInternal_ToCorner;

        // External FUll
        this.PosCircleExternalFull_FullHidden  = String.Format(this.Coord, this.posEditionX,this.EditionHeight -this.CircleHeight);
        this.PosCircleExternalFull_Edition     = String.Format(this.Coord, this.posEditionX, this.OffsetCircleTop);
        this.PosCircleExternalFull_ToCornerTop = this.PosCircleExternalFull_FullHidden;

        // Internal Full
        this.PosCircleInternalFull_FullHidden  = String.Format(this.Coord, 0, -this.CircleHeight);
        this.PosCircleInternalFull_Edition     = String.Format(this.Coord, 0, this.OffsetCircleTop);
        this.PosCircleInternalFull_ToCornerTop = this.PosCircleInternalFull_FullHidden;

        // Tensions Name ------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        // External
        this.PosTensionNameExternal_FullHidden     = String.Format(this.Coord, this.PosX + this.OffsetAxis + this.offsetCircleWidth + 50, this.PosY - this.OffsetAxis);
        this.PosTensionNameExternal_Mission        = this.PosTensionNameExternal_FullHidden;
        this.PosTensionNameExternal_MissionUnfocus = this.PosTensionNameExternal_FullHidden;
        this.PosTensionNameExternal_ToCorner       =  String.Format(this.Coord, posToCornerX + this.offsetCircleWidth + 50, posToCornerY);
        this.PosTensionNameExternal_ToCornerTop    = String.Format(this.Coord, this.posEditionX + this.EditionWidth - this.CircleWidth + 50, this.posEditionY);
        this.PosTensionNameExternal_Edition        = String.Format(this.Coord, this.posEditionX + this.EditionWidth - this.CircleWidth + 50, this.posEditionY + this.OffsetCircleTop + this.TensionNameHeight);

        // Internal
        this.PosTensionNameInternal_FullHidden     = String.Format(this.Coord, this.PosX + this.OffsetAxis + 30, this.PosY - this.OffsetAxis + this.offsetCircleHeight + (this.CircleHeight - this.TensionNameHeight));
        this.PosTensionNameInternal_Mission        = this.PosTensionNameInternal_FullHidden;
        this.PosTensionNameInternal_MissionUnfocus = this.PosTensionNameInternal_FullHidden;
        this.PosTensionNameInternal_ToCorner       = String.Format(this.Coord, posToCornerX + this.OffsetAxis + 10, posToCornerY + this.offsetCircleHeight  - this.OffsetAxis + (this.CircleHeight - this.TensionNameHeight));
        this.PosTensionNameInternal_Edition        = String.Format(this.Coord, posToCornerX + this.OffsetAxis + 10, this.posEditionY  + this.OffsetCircleTop + this.TensionNameHeight);
    }
}

export let ValueCreationInfo:ValueCreationData = new ValueCreationData(
    Enums.Key.ValueCreation,
    /*PosX              */2500,
    /*PosY              */150,
    /*RectangleHeight   */1000,
    /*RectangleWidth    */1000,
    /*CircleHeight      */350,
    /*CircleWidth       */350,
    /*FIll              */'rgb(16,28,65)',
    /*TensionNameHeight */250,
    /*TensionNameWidth  */250
    );
