import { Injectable } from '@angular/core';
import { WebSocketSubject } from 'rxjs/observable/dom/WebSocketSubject';
import { Message } from 'projects/btlibrary/src/lib/messaging/Message';
import { environment } from '../../../../BtModeration/src/environments/environment';
import { ViewClientComponent } from '../components/view-client/view-client.component';
import { Action } from '../messaging/Action';

@Injectable({
    providedIn: 'root'
})
export class MessagingService {
    readonly endpoint = environment.messagingUrl; // 'ws://localhost:8999'
    public socket$: WebSocketSubject<Message>;

    constructor() {
        this.socket$ = new WebSocketSubject(this.endpoint);

        this.socket$
            .subscribe(
                (err) => console.error(err),
                () => console.warn('Completed!')
            );
    }

    public SendMessage(action: string, parameters: string[] = []) {
        // send message to local ViewClient
        ViewClientComponent.self.ContextActions.Execute(
            [{ 'Context': 'viewClient' , 'Action' : action, 'Parameters' : parameters } ] );

        // send message to ViewClients!
        const anAction = new Action('viewClient', action, parameters);
        const message = new Message('Moderator', anAction);
        this.socket$.next(message);
    }
}
