import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { SvgText, TextAnchorWidth, TextAnchorHeight } from '../../class/platform';
import {  DimensionsStates, Key, StateValue, LabelTension } from '../../class/application';
import * as Animations from './label-main-tensions.animations';

@Component({
    selector: '[bt-label-main-tensions]',
    templateUrl: './label-main-tensions.component.html',
    styleUrls: ['./label-main-tensions.component.css'],
    animations: [
        Animations.Resources,
        Animations.ValueCreation,
        Animations.Leadership,
        Animations.SelfLeadership,
        Animations.ResourcesMission,
        Animations.ValueCreationMission,
        Animations.LeadershipMission,
        Animations.SelfLeadershipMission,
        Animations.ResourcesEdition,
        Animations.ValueCreationEdition,
        Animations.LeadershipEdition,
        Animations.SelfLeadershipEdition,
    ]
})

export class LabelMainTensionsComponent extends DimensionsStates implements OnInit {

    constructor() {
        super();
        this.RegisterState( { Key : Key.Resources, StateValue : StateValue.FullHidden });
        this.RegisterState( { Key : Key.ValueCreation, StateValue : StateValue.FullHidden });
        this.RegisterState( { Key : Key.Leadership, StateValue : StateValue.FullHidden });
        this.RegisterState( { Key : Key.SelfLeadership, StateValue  :  StateValue.FullHidden });

        this.RegisterState( { Key : Key.ResourcesMission, StateValue : StateValue.FullHidden });
        this.RegisterState( { Key : Key.ValueCreationMission, StateValue : StateValue.FullHidden });
        this.RegisterState( { Key : Key.LeadershipMission, StateValue : StateValue.FullHidden });
        this.RegisterState( { Key : Key.SelfLeadershipMission, StateValue  :  StateValue.FullHidden });

        this.RegisterState( { Key : Key.ResourcesEdition, StateValue : StateValue.FullHidden });
        this.RegisterState( { Key : Key.ValueCreationEdition, StateValue : StateValue.FullHidden });
        this.RegisterState( { Key : Key.LeadershipEdition, StateValue : StateValue.FullHidden });
        this.RegisterState( { Key : Key.SelfLeadershipEdition, StateValue  :  StateValue.FullHidden });

        this.labelMainTensions[Key.Resources] = new LabelTension();
        this.labelMainTensions[Key.ValueCreation] = new LabelTension();
        this.labelMainTensions[Key.Leadership] = new LabelTension();
        this.labelMainTensions[Key.SelfLeadership] = new LabelTension();
   }

    private labelMainTensions : { [tensionName: string] : LabelTension } = {};

     // Object resources **********************************************************************************************************************************************************
    @Input()
        get resourcesState(){
            return this.GetState(Key.Resources);
        }
        set resourcesState(value : string ){
            this.SetState(Key.Resources, value);
        }

    // Object resourcesMission **********************************************************************************************************************************************************
    @Input()
        get resourcesMissionState(){
            return this.GetState(Key.ResourcesMission);
        }
        set resourcesMissionState(value : string ){
            this.SetState(Key.ResourcesMission, value);
        }

    // Object resourcesEdition **********************************************************************************************************************************************************
    @Input()
        get resourcesEditionState(){
            return this.GetState(Key.ResourcesEdition);
        }
        set resourcesEditionState(value : string ){
            this.SetState(Key.ResourcesEdition, value);
        }

    // Object ValueCreation **********************************************************************************************************************************************************
    @Input()
        get valueCreationState(){
            return this.GetState(Key.ValueCreation);
        }
        set valueCreationState(value : string ){
            this.SetState(Key.ValueCreation, value);
        }

    // Object ValueCreationMission **********************************************************************************************************************************************************
    @Input()
        get valueCreationMissionState(){
            return this.GetState(Key.ValueCreationMission);
        }
        set valueCreationMissionState(value : string ){
            this.SetState(Key.ValueCreationMission, value);
        }

    // Object ValueCreationEdition **********************************************************************************************************************************************************
    @Input()
        get valueCreationEditionState(){
            return this.GetState(Key.ValueCreationEdition);
        }
        set valueCreationEditionState(value : string ){
            this.SetState(Key.ValueCreationEdition, value);
        }

    // Object Leadership **********************************************************************************************************************************************************
    @Input()
        get leadershipState(){
            return this.GetState(Key.Leadership);
        }
        set leadershipState(value : string ){
            this.SetState(Key.Leadership, value);
        }

    // Object LeadershipMission **********************************************************************************************************************************************************
    @Input()
        get leadershipMissionState(){
            return this.GetState(Key.LeadershipMission);
        }
        set leadershipMissionState(value : string ){
            this.SetState(Key.LeadershipMission, value);
        }

    // Object LeadershipEdition **********************************************************************************************************************************************************
    @Input()
        get leadershipEditionState(){
            return this.GetState(Key.LeadershipEdition);
        }
        set leadershipEditionState(value : string ){
            this.SetState(Key.LeadershipEdition, value);
        }

    // Object LeftLeadership **********************************************************************************************************************************************************
    @Input()
        get selfLeadershipState(){
            return this.GetState(Key.SelfLeadership);
        }
        set selfLeadershipState(value : string ){
            this.SetState(Key.SelfLeadership, value);
        }

    // Object LeftLeadershipMission **********************************************************************************************************************************************************
    @Input()
        get selfLeadershipMissionState(){
            return this.GetState(Key.SelfLeadershipMission);
        }
        set selfLeadershipMissionState(value : string ){
            this.SetState(Key.SelfLeadershipMission, value);
        }

    // Object LeftLeadershipEdition **********************************************************************************************************************************************************
    @Input()
        get selfLeadershipEditionState(){
            return this.GetState(Key.SelfLeadershipEdition);
        }
        set selfLeadershipEditionState(value : string ){
            this.SetState(Key.SelfLeadershipEdition, value);
        }

    //  Resources ---------------------------------------------------------------------------------------------------------------------------------------------------------------
    @ViewChild(Key.Resources) labelResourcesDOM: ElementRef;
    @ViewChild(Key.ResourcesMission) labelResourcesMissionDOM: ElementRef;
    @ViewChild(Key.ResourcesEdition) labelResourcesEditionDOM: ElementRef;

    @Input()
        get labelResources(){
            return this.labelMainTensions[Key.Resources].label;
        }
        set labelResources(value: string){
            this.labelMainTensions[Key.Resources].label = value;
            SvgText(this.labelResourcesDOM, value, 50, 80, TextAnchorWidth.middle, 1000, TextAnchorHeight.middle, 1000);
            SvgText(this.labelResourcesMissionDOM, value, 50, 50, TextAnchorWidth.middle, 1000, TextAnchorHeight.middle, 1000);
            SvgText(this.labelResourcesEditionDOM, value, 50, 80, TextAnchorWidth.middle, 4205, TextAnchorHeight.middle, 300);
        }

    //  ValueCreation ---------------------------------------------------------------------------------------------------------------------------------------------------------------
    @ViewChild(Key.ValueCreation) labelValueCreationDOM: ElementRef;
    @ViewChild(Key.ValueCreationMission) labelValueCreationMissionDOM: ElementRef;
    @ViewChild(Key.ValueCreationEdition) labelValueCreationEditionDOM: ElementRef;

    @Input()
        get labelValueCreation(){
            return this.labelMainTensions[Key.ValueCreation].label;
        }
        set labelValueCreation(value: string){
            this.labelMainTensions[Key.ValueCreation].label = value;
            SvgText(this.labelValueCreationDOM, value, 50, 80, TextAnchorWidth.middle, 1000, TextAnchorHeight.middle, 1000);
            SvgText(this.labelValueCreationMissionDOM, value, 50, 50, TextAnchorWidth.middle, 1000, TextAnchorHeight.middle, 1000);
            SvgText(this.labelValueCreationEditionDOM, value, 50, 80, TextAnchorWidth.middle, 4205, TextAnchorHeight.middle, 380);
        }

    //  Leadership ---------------------------------------------------------------------------------------------------------------------------------------------------------------
    @ViewChild(Key.Leadership) labelLeadershipDOM: ElementRef;
    @ViewChild(Key.LeadershipMission) labelLeadershipMissionDOM: ElementRef;
    @ViewChild(Key.LeadershipEdition) labelLeadershipEditionDOM: ElementRef;

    @Input()
        get labelLeadership(){
            return this.labelMainTensions[Key.Leadership].label;
        }
        set labelLeadership(value: string){
            this.labelMainTensions[Key.Leadership].label = value;
            SvgText(this.labelLeadershipDOM, value, 50, 80, TextAnchorWidth.middle, 1000, TextAnchorHeight.middle, 1000);
            SvgText(this.labelLeadershipMissionDOM, value, 50, 80, TextAnchorWidth.middle, 1000, TextAnchorHeight.middle, 1000);
            SvgText(this.labelLeadershipEditionDOM, value, 50, 80, TextAnchorWidth.middle, 4205, TextAnchorHeight.middle, 380);
        }

    //  SelfLeadership ---------------------------------------------------------------------------------------------------------------------------------------------------------------
    @ViewChild(Key.SelfLeadership) labelSelfLeadershipDOM: ElementRef;
    @ViewChild(Key.SelfLeadershipMission) labelSelfLeadershipMissionDOM: ElementRef;
    @ViewChild(Key.SelfLeadershipEdition) labelSelfLeadershipEditionDOM: ElementRef;

    @Input()
        get labelSelfLeadership(){
            return this.labelMainTensions[Key.SelfLeadership].label;
        }
        set labelSelfLeadership(value: string){
            this.labelMainTensions[Key.SelfLeadership].label = value;
            SvgText(this.labelSelfLeadershipDOM, value, 50, 80, TextAnchorWidth.middle, 1000, TextAnchorHeight.middle, 1000);
            SvgText(this.labelSelfLeadershipMissionDOM, value, 50, 80, TextAnchorWidth.middle, 1000, TextAnchorHeight.middle, 1000);
            SvgText(this.labelSelfLeadershipEditionDOM, value, 50, 80, TextAnchorWidth.middle, 4205, TextAnchorHeight.middle, 380);
        }

    ngOnInit() {
    }

    public SetLabel(tensionName: Key, value: string){
        switch(tensionName) {
            case Key.Resources:
                this.labelResources = value;
                break;
            case Key.ValueCreation:
                this.labelValueCreation = value;
                break;
            case Key.Leadership:
                this.labelLeadership = value;
                break;
            case Key.SelfLeadership:
                this.labelSelfLeadership = value;
                break;
        }
    }

    private SetTensionsValue( value: StateValue){
        this.resourcesState = value;
        this.valueCreationState = value;
        this.leadershipState = value;
        this.selfLeadershipState = value;
    }

    private SetMissionsValue( value: StateValue){
        this.resourcesMissionState = value;
        this.valueCreationMissionState = value;
        this.leadershipMissionState = value;
        this.selfLeadershipMissionState = value;
    }

    private SetMissionFromEdition(key: string, value: string) {
        this.SetStateAction(key, value, null, null,
            () => {
                this.SetMissionsValue(StateValue.Mission);
            });
    }

    //STATES********************************************************************************************************************************************/
    public SetStateTensions(oldValue: StateValue, newValue: StateValue){
        this.SetMissionsValue(StateValue.FullHidden);
        this.SetTensionsValue(StateValue.Tension);
    }

    public SetStateMissions(oldValue: StateValue, newValue: StateValue){
        switch (oldValue){
            case StateValue.ResourcesEdition:
                this.SetMissionFromEdition(Key.ResourcesEdition, StateValue.ToCornerFromResourcesEdition);
                break;
            case StateValue.ValueCreationEdition:
                this.SetMissionFromEdition(Key.ValueCreationEdition, StateValue.ToCornerFromValueCreationEdition);
                break;
            case StateValue.LeadershipEdition:
                this.SetMissionFromEdition(Key.LeadershipEdition, StateValue.ToCornerFromLeadershipEdition);
                break;
            case StateValue.SelfLeadershipEdition:
                this.SetMissionFromEdition(Key.SelfLeadershipEdition, StateValue.ToCornerFromSelfLeadershipEdition);
                break;
            default:
                this.SetTensionsValue(StateValue.FullHidden);
                this.SetMissionsValue(StateValue.Mission);
                break;
        }
    }

    public SetStateMission(key: Key){
        var values = {};
        values[Key.Resources] = StateValue.MissionUnfocus;
        values[Key.ValueCreation] = StateValue.MissionUnfocus;
        values[Key.Leadership] = StateValue.MissionUnfocus;
        values[Key.SelfLeadership] = StateValue.MissionUnfocus;

        values[key] = StateValue.Mission;

        this.resourcesMissionState = values[Key.Resources];
        this.valueCreationMissionState = values[Key.ValueCreation];
        this.leadershipMissionState = values[Key.Leadership];
        this.selfLeadershipMissionState = values[Key.SelfLeadership];
    }

    public SetStateEdition(key: Key, state: StateValue){
        this.SetStateAction(key + 'Mission', state, null, null,
            () =>{
                this.SetStateAction(key + 'Mission',StateValue.Edition, null, null,
                    (value)=>{
                        this[key + 'EditionState'] = StateValue.Edition;
                    });
            });

        if (state != StateValue.ToCornerFromResourcesMission){
            this.resourcesMissionState = state;
        }

        if (state != StateValue.ToCornerFromValueCreationMission){
            this.valueCreationMissionState = state;
        }

        if (state != StateValue.ToCornerFromLeadershipMission){
            this.leadershipMissionState = state;
        }

        if (state != StateValue.ToCornerFromSelfLeadershipMission){
            this.selfLeadershipMissionState = state;
        }
    }
}
