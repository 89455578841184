import { Component, OnInit } from '@angular/core';
import { ViewClientComponent } from 'projects/btlibrary/src/lib/components/view-client/view-client.component';
import { MessagingService } from 'projects/btlibrary/src/lib/services/messaging.service';

@Component({
  selector: 'bt-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.css']
})
export class MissionComponent implements OnInit {
  constructor(private messagingService: MessagingService) { }

  ngOnInit() {
    // this.ToMissions();
  }

  // ToMissions() {
  //   this.messagingService.SendMessage('ToMissions');
  // }
}
