import { Input, Output, EventEmitter } from '@angular/core';
import { ElementState } from './element-state';

export class BTControllerStates {
    private Invetory : { [IdDOM:string] : ElementState} = {};

    /**
     * Default emmiter to owner state
     *
     * @memberof BTControllerStates
     */
    @Output() ownerStateChange = new EventEmitter();

    @Input() ownerState_: string;

    @Input()
        get ownerState(){
            return this.ownerState_;
        }
        set ownerState(value: string){
            var previewsOwnerState = this.ownerState_;
            this.ownerState_ = value;
            this.OwnerStateNotify(previewsOwnerState, value);
        }

    /**
     * Dummy function to set change en owner state, this function is override
     *
     * @param {string} oldValue
     * @param {string} newValue
     * @memberof BTControllerStates
     */
    public OwnerStateNotify(oldValue: string, newValue: string) {};

    /**
     * Register state and set initial value state
     *
     * @param {{ Key, StateValue, NOtify, Start, Done}} state
     * @memberof BTControllerStates
     */
    public RegisterState( state: {}){
        let key:string = this.NormalizeKey(state['Key']);
        this.Invetory[key] = new ElementState(key, this, state['Notify'],  state['Start'], state['Done']);
        this.SetState(key, state['StateValue']);
    }

    /**
     * Get the current state relationship width specify context
     *
     * @param {string} key
     * @returns {string}
     * @memberof BTControllerStates
     */
    public GetState(key:string): string{
        key = this.NormalizeKey(key);
        return String(this.Invetory[key].State);
    }

    /**
     *
     * Set the current state relationship width specify context
     *
     * @param {string} key
     * @param {string} value
     * @memberof BTControllerStates
     */
    public SetState(key: string, value: string){
        key = this.NormalizeKey(key);
        if (this.Invetory[key])
        {
            var owner:ElementState = this.Invetory[key];
            owner.State = value;

            var notifiers = Object.getOwnPropertyNames(owner.InvetoryNotifications);
            for (let index = 0; index < notifiers.length; index++) {
                const element = notifiers[index];
                owner.InvetoryNotifications[element].apply( owner, [value]);
            }
            if (owner.Notify){
                owner.Notify(value);
            }
        }
    }

    private NormalizeKey(value: string) {
        return value.toLowerCase().replace("animation","");
    }

    /**
     * Set state to specify context and add trigger to notify, start and done state
     *
     * @param {string} key
     * @param {string} value
     * @param {Function} [notify]
     * @param {Function} [start]
     * @param {Function} [done]
     * @memberof BTControllerStates
     */
    public SetStateAction(key: string, value: string, notify?: Function, start?: Function,done?: Function){
        key = this.NormalizeKey(key);
        if (this.Invetory[key]){
            var owner:ElementState = this.Invetory[key];
            if (start){
                owner.QueueStart.unshift(start);
            }
            if (done){
                owner.QueueDone.unshift(done);
            }
            this.SetState(key, value);
            if (notify){
                owner.InvetoryNotifications[value] = notify;
            }
        }
    }

    public RemoveNotification(key: string, value: string){
        key = this.NormalizeKey(key);
        if (this.Invetory[key]){
            this.Invetory[key].RemoveNotification(value);
        }
    }

    /**
     * Trigger the event start state
     *
     * @param {*} $event
     * @memberof BTControllerStates
     */
    public Start($event: any){
        let key = this.NormalizeKey($event.triggerName);
        if (this.Invetory[key]){
            var owner:ElementState = this.Invetory[key];
            var start = owner.QueueStart.shift();
            if (start){
                try {
                    start.apply(owner, [event]);
                    if (owner.QueueStart.length == 0 && this.Invetory[key].Start){
                        this.Invetory[key].Start($event);
                    }
                }  catch (error) {
                    console.log(error);
                }
            } else if (this.Invetory[key].Start){
                this.Invetory[key].Start($event);
            }
        }
    }

    /**
     * Trigger the event done state
     *
     * @param {*} $event
     * @memberof BTControllerStates
     */
    public Done($event: any){
        let key = this.NormalizeKey($event.triggerName);
        if (this.Invetory[key]){
            var owner:ElementState = this.Invetory[key];
            var done = owner.QueueDone.shift();
            if (done){
                try {
                    done.apply(owner, [event]);
                    if (owner.QueueDone.length == 0 && this.Invetory[key].Done){
                        this.Invetory[key].Done($event);
                    }
                }  catch (error) {
                    console.log(error);
                }
            } else if (this.Invetory[key].Done){
                this.Invetory[key].Done($event);
            }
        }
    }

    TriggerOwnerStateChange(value: string){
        this.ownerState = value;
        this.ownerStateChange.emit(this.ownerState_);
    }
}
