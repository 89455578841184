import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { SvgText } from '../../class/platform/svg-text';
import { TextAnchorWidth, TextAnchorHeight } from '../../class/platform/enums';
import { BTControllerStates } from '../../class/platform/bt-controller-states';
import * as Animations from './main-problem-animations';
import * as Enums from '../../class/application/enums';

@Component({
  selector: '[bt-main-problem]',
  templateUrl: './main-problem.component.html',
  styleUrls: ['./main-problem.component.css'],
  animations: [
    Animations.Description
  ]
})
export class MainProblemComponent extends BTControllerStates implements OnInit {
    constructor() {
        super();
        this.RegisterState(
            {
                Key         : Enums.Key.MainProblemDescription,
                StateValue  : Enums.StateValue.Show,
                Done        : () => {
                    if (this.descriptionState == Enums.StateValue.HiddenDescription){
                       this.TriggerOwnerStateChange(Enums.StateValue.Hidden);
                    }
                }
            });
    }

    // svg description **********************************************************************************************************************************************************
    @ViewChild(Enums.Key.MainProblemDescription) descriptionDOM: ElementRef;

    public description_: string;

    @Input()
        get description(){
            return this.description_;
        }
        set description(value: string){
            this.description_ = value;
            SvgText(this.descriptionDOM, this.description, 80, 150, TextAnchorWidth.middle, 2000, TextAnchorHeight.middle, 2000);
        }

    @Input()
        get descriptionState(){
            return this.GetState(Enums.Key.MainProblemDescription);
        }
        set descriptionState(value : string ){
            this.SetState(Enums.Key.MainProblemDescription, value);
        }

    //********************************************************************************************************************************************************************************************************************************************************************************************************************

    ngOnInit() {
    }

    public OwnerStateNotify (oldValue: Enums.StateValue, newValue: Enums.StateValue){
        if ([Enums.StateValue.Show, Enums.StateValue.HiddenDescription].indexOf(newValue) > -1){
            this.descriptionState = newValue;
        }
    }
}
