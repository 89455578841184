import { Subtension } from './Subtension';
import { ITension } from './ITension';
import { Trend } from './Trend';
import { Initiative } from './Initiative';
import { Group } from './Group';

export class Tension implements ITension {
  public id: number;
  public defName: string;
  public name: string;
  public extName: string;
  public intName: string;
  public yesterday = 25;
  public today = 50;
  public tomorrow = 75;
  public subtensions: Subtension[];
  public trends: Trend[];
  public groupTrends: Group[];
  public initiatives: Initiative[];

  constructor (
    defName: string,
    name: string = null,
    extName: string = null,
    intName: string = null,
  ) {
    this.defName = defName;
    this.name = name == null ? defName : name;
    this.extName = extName == null ? 'External ' + defName : extName;
    this.intName = intName == null ? 'Internal ' + defName : intName;
  }
}
