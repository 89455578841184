import { BTControllerStates } from "../platform";
import { Key, StateValue } from './enums';

export class DimensionsStates  extends BTControllerStates  {

    constructor() {
        super();
    }

    // Common methods*****************************************************************************************************************************************************************************
    public OwnerStateNotify(oldValue: StateValue, newValue: StateValue){
        switch (newValue){
            case StateValue.Tensions:
                this.SetStateTensions(oldValue, newValue);
                break;
            case StateValue.Missions:
                this.SetStateMissions(oldValue, newValue);
                break;
            case StateValue.ResourcesMission:
                this.SetStateMission(Key.Resources);
                break;
            case StateValue.ValueCreationMission:
                this.SetStateMission(Key.ValueCreation);
                break;
            case StateValue.LeadershipMission:
                this.SetStateMission(Key.Leadership);
                break;
            case StateValue.SelfLeadershipMission:
                this.SetStateMission(Key.SelfLeadership);
                break;
            case StateValue.ResourcesEdition:
                this.SetStateEdition(Key.Resources, StateValue.ToCorner);
                break;
            case StateValue.ValueCreationEdition:
                this.SetStateEdition(Key.ValueCreation, StateValue.ToCorner);
                break;
            case StateValue.LeadershipEdition:
                this.SetStateEdition(Key.Leadership, StateValue.ToCorner);
                break;
            case StateValue.SelfLeadershipEdition:
                this.SetStateEdition(Key.SelfLeadership, StateValue.ToCorner);
                break;
        }
    }

    //STATES********************************************************************************************************************************************/
    public SetStateTensions(oldValue: StateValue, newValue: StateValue){}
    public SetStateMissions(oldValue: StateValue, newValue: StateValue){}
    public SetStateMission(key: Key){}
    public SetStateEdition(key: Key, Sstate: StateValue){}
}
