import { Component, Input } from '@angular/core';
import { Initiative } from '../../data/Initiative';

@Component({
  selector: 'bt-initiative',
  templateUrl: './initiative.component.html',
  styleUrls: ['./initiative.component.css']
})
export class InitiativeComponent {
  @Input() initiative: Initiative;

  constructor() { }
}

