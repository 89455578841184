import { Component, OnInit, Input } from '@angular/core';
import { Project } from 'projects/btlibrary/src/lib/data/Project';
import { Event } from 'projects/btlibrary/src/lib/data/Event';
import { ProjectService } from 'projects/btlibrary/src/lib/services/project.service';
import { MessagingService } from 'projects/btlibrary/src/lib/services/messaging.service';
import { ViewClientComponent } from 'projects/btlibrary/src/lib/components/view-client/view-client.component';

@Component({
  selector: 'bt-critical-events',
  templateUrl: './critical-events.component.html',
  styleUrls: ['./critical-events.component.css']
})
export class CriticalEventsComponent implements OnInit {
  project: Project;
  curEvent: Event;

  constructor(
    private projectService: ProjectService,
    private messagingService: MessagingService
  ) {
  }

  private mainProblem_: string;

  @Input()
    get mainProblem() {
        return this.mainProblem_;
    }
    set mainProblem(value: string) {
        this.mainProblem_ = value;
        alert('a');
    }


  ngOnInit() {
    this.getProject();
  }

  getProject(): void {
    this.projectService.getProject()
        .subscribe(project => {
          this.project = project;
          this.curEvent = this.project.criticalEvents[0];
        });
  }

  public SelectEvent(id: number) {
    this.curEvent = this.project.criticalEvents[id];
  }

  public AddNewEvent() {
    const newId = this.project.criticalEvents.length;
    this.project.criticalEvents[newId] = { id: newId, name: 'new event', author: '', description: '' };
    this.curEvent = this.project.criticalEvents[newId];
  }
}
