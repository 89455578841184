import { Project } from '../data/Project';
import { ArrowKind } from '../data/Enums';

export class Mock {
  static Project: Project = {
    id: 1,
    mandant: 'Raiffeisen Scheweiz',
    name: 'Raiffeisen Relationship',
    owner: 'Daniel Lüscher',
    description: 'Relationship between Raiffeisen Scheweiz and other Banks',
    start: '2018-01-01',
    end: '2020-12-31',
    mainProblem: 'How should the relationship between Raiffeisen Scheweiz and the Banks be structured',
    criticalEvents: [
        {
          id: 0,
          name: 'Foundation of Raiffeisen Scheweiz',
          author: 'Daniel Lüscher',
          description: 'Genossenschaften gründen RCH, um gesisse Aufgaben zusammenzulegen.'
        },
        {
          id: 1,
          name: 'Pierin Vincenz is accused of insider trading',
          author: 'Daniel Lüscher, Urs Schneider',
          description: 'Vincenz wird festgenommen mit der Verdachtigung auf Insider-Geschafte.'
        },
        {
          id: 2,
          name: 'Regional President talks in the news',
          author: 'Daniel Lüscher, Rolf Walker',
          description: 'Vincenz wird festgenommen mit der Verdachtigung auf'
        }
      ],
    tensions: [
      {
        id: 1,
        defName: 'Resources',
        name: 'Woher baziehen wir die Ressourcen?',
        extName: 'Vor Ort', // 'Durch Nahe zu den Banken und Genossenschaften',
        intName: 'Zentral organisiert', // 'Durch zentrale zur Verfügungstellung'
        yesterday: 100,
        today: 50,
        tomorrow: 0,
        subtensions: [
            {
                id: 1,
                extName: 'Subtension1 External',
                intName: 'Subtension1 Internal',
                yesterday: 10,
                today: 20,
                tomorrow: 30,
            },
            {
                id: 2,
                extName: 'Subtension2 External',
                intName: 'Subtension2 Internal',
                yesterday: 75,
                today: 50,
                tomorrow: 25,
            }
        ],
        trends: [
          {
            id: 1,
            name: 'Trend1',
            direction: 0,
            knowledge: 4,
            impact: -1,
            progress: -1,
            risk: -1,
            kind: ArrowKind.Trend,
          },
          {
            id: 2,
            name: 'Trend2',
            direction: 1,
            knowledge: 2,
            impact: -1,
            progress: -1,
            risk: -1,
            kind: ArrowKind.Trend,
          },
          {
            id: 3,
            name: 'Trend3',
            direction: 1,
            knowledge: 2,
            impact: -1,
            progress: -1,
            risk: -1,
            kind: ArrowKind.Trend,
          },
          {
            id: 4,
            name: 'Trend4',
            direction: 1,
            knowledge: 2,
            impact: -1,
            progress: -1,
            risk: -1,
            kind: ArrowKind.Trend,
          },
        ],
        groupTrends: [
          {
            id: 0,
            name: 'group1',
            trends: []
          },
          {
            id: 1,
            name: 'group2',
            trends: []
          }
        ],
        initiatives: [
          {
            id: 1,
            name: 'InitA1',
            direction: 0,
            knowledge: 4,
            impact: 0,
            progress: 0,
            risk: 0,
            kind: ArrowKind.Initiative,
          },
        ]
      },
      {
        id: 2,
        defName: 'Value Creation',
        name: 'Welches ist unser Wertbeitrag?',
        extName: 'Genossenschaftlich', // 'Genossenschaftlich, Komerziell',
        intName: 'Komerziell', // 'Betriebswirtschaflich, Komerziell'
        yesterday: 30,
        today: 80,
        tomorrow: 50,
        subtensions: [
            {
              id: 1,
              extName: 'Subtension1b External',
              intName: 'Subtension1b Internal',
              yesterday: 10,
              today: 20,
              tomorrow: 30,
          },
          {
              id: 2,
              extName: 'Subtension2b External',
              intName: 'Subtension2b Internal',
              yesterday: 25,
              today: 50,
              tomorrow: 75,
          }
        ],
        trends: [
          {
            id: 1,
            name: 'Trend1',
            direction: 1,
            knowledge: 2,
            impact: -1,
            progress: -1,
            risk: -1,
            kind: ArrowKind.Trend,
          },
          {
            id: 2,
            name: 'Trendb2',
            direction: 0,
            knowledge: 1,
            impact: -1,
            progress: -1,
            risk: -1,
            kind: ArrowKind.Trend,
          },
          {
            id: 3,
            name: 'Trendb3',
            direction: 1,
            knowledge: 4,
            impact: -1,
            progress: -1,
            risk: -1,
            kind: ArrowKind.Trend,
          },
        ],
        groupTrends: [],
        initiatives: [
          {
            id: 1,
            name: 'InitB1',
            direction: 0,
            knowledge: 4,
            impact: 0,
            progress: 0,
            risk: 1,
            kind: ArrowKind.Initiative,
          },
        ]
      },
      {
        id: 3,
        defName: 'Self-Leadership',
        name: 'Was ist unsere Motivation?',
        extName: 'Mitverantwortung', // 'Führungsgremium der Gesamt-Gruppe',
        intName: 'Teil des Systems', // 'Führungsgremium von Raiffeisen Scheweiz'
        yesterday: 30,
        today: 50,
        tomorrow: 70,
        subtensions: [
          {
            id: 1,
            extName: 'Subtension1c External',
            intName: 'Subtension1c Internal',
            yesterday: 10,
            today: 25,
            tomorrow: 50,
          },
          {
              id: 2,
              extName: 'Subtension2c External',
              intName: 'Subtension2c Internal',
              yesterday: 20,
              today: 60,
              tomorrow: 85,
          }
        ],
        trends: [],
        groupTrends: [],
        initiatives: []
      },
      {
        id: 4,
        defName: 'Leadership',
        name: 'Wie führen wir?',
        extName: 'Kollektiv', // 'Genossenschaftlich, Komerziell',
        intName: 'Direcktiv', // 'Kollektiv, Gemeinschaftlich'
        yesterday: 25,
        today: 45,
        tomorrow: 65,
        subtensions: [
          {
            id: 1,
            extName: 'Subtension1d External',
            intName: 'Subtension1d Internal',
            yesterday: 10,
            today: 35,
            tomorrow: 60,
          },
        ],
        trends: [],
        groupTrends: [],
        initiatives: [
          {
            id: 1,
            name: 'InitD1',
            direction: 0,
            knowledge: 4,
            impact: 0,
            progress: 40,
            risk: 0,
            kind: ArrowKind.Initiative,
          },
          {
            id: 2,
            name: 'InitD2',
            direction: 1,
            knowledge: 3,
            impact: 3,
            progress: 30,
            risk: 2,
            kind: ArrowKind.Initiative,
          },
          {
            id: 3,
            name: 'InitD3',
            direction: 1,
            knowledge: 2,
            impact: 2,
            progress: 70,
            risk: 1,
            kind: ArrowKind.Initiative,
          },
        ]
      }
    ],
  };
}
