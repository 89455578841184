import { Component, Input } from '@angular/core';
import { Tension } from '../../data/Tension';

@Component({
  selector: 'bt-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent {
  @Input() tension: Tension;

  constructor() { }

  public test(s: string) {
    console.log('this click event is working for: ' + s);
  }
}

