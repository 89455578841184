import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ContextActions, BTControllerStates } from '../../class/platform';
import * as Animations from './view-client-animations';
import { Key, StateValue } from '../../class/application';
import { LabelMainTensionsComponent } from '../label-main-tensions/label-main-tensions.component';
import { Project } from '../../data/Project';
import { ProjectService } from '../../services/project.service';
import { MessagingService } from '../../services/messaging.service';
import { Message } from '../../messaging/Message';

@Component({
    selector: 'bt-view-client',
    templateUrl: './view-client.component.html',
    styleUrls: ['./view-client.component.css'],
    animations: [
        Animations.Logo,
        Animations.ViewClient,
        Animations.MainProblem,
        Animations.DimensionResources,
        Animations.DimensionValueCreation,
        Animations.DimensionLeadership,
        Animations.DimensionSelfLeadership,
    ]
})

export class ViewClientComponent extends BTControllerStates implements OnInit {
    static self: ViewClientComponent;
    project: Project;
    @Input() mode: string;

    constructor (
        private projectService: ProjectService,
        private messagingService: MessagingService
    ) {
        super();

        this.messagingService.socket$
            .subscribe(
                (message) => this.ExecuteAction(message),
            );

        self = this;
        ViewClientComponent.self = this;

        this.ContextActions.Register(Key.ViewClient, this);

        this.RegisterState({ Key : Key.MainProblem, StateValue : StateValue.FullHidden });
        this.RegisterState({ Key : Key.RectangleState, StateValue : StateValue.Initial });
        this.RegisterState({ Key : Key.CircleState, StateValue : StateValue.Initial });

        this.RegisterState({ Key : Key.ResourcesState, StateValue : StateValue.Initial });
        this.RegisterState({ Key : Key.ValueCreationState, StateValue : StateValue.Initial });
        this.RegisterState({ Key : Key.LeadershipState, StateValue : StateValue.Initial });
        this.RegisterState({ Key : Key.SelfLeadershipState, StateValue : StateValue.Initial });

        this.RegisterState({ Key : Key.DimensionResources, StateValue : StateValue.Initial });
        this.RegisterState({ Key : Key.DimensionValueCreation, StateValue : StateValue.Initial });
        this.RegisterState({ Key : Key.DimensionLeadership, StateValue : StateValue.Initial });
        this.RegisterState({ Key : Key.DimensionSelfLeadership, StateValue : StateValue.Initial });

        this.ToLogo();
    }

    public ContextActions: ContextActions = new ContextActions();
    public ClientState: StateValue;
    public CurrentDimension: string;

    @ViewChild(LabelMainTensionsComponent) CmpLabelMainTensions: LabelMainTensionsComponent;

    // Object Logo **********************************************************************************************************************************************************
    @Input()
        get logoState(){
            return this.GetState(Key.Logo);
        }
        set logoState(value : string ){
            this.SetState(Key.Logo, value);
        }

    // Object ViewClient **********************************************************************************************************************************************************
    @Input()
        get viewClientState(){
            return this.GetState(Key.ViewClient);
        }
        set viewClientState(value : string ){
            this.SetState(Key.ViewClient, value);
        }

    // Component MainProblem **********************************************************************************************************************************************************
    @Input()
        get mainProblemState(){
            return this.GetState(Key.MainProblem);
        }
        set mainProblemState(value : string ){
            this.SetState(Key.MainProblem, value);
        }
    // Component Rectangles ***********************************************************************************************************************************************
    @Input()
        get rectanglesState(){
            return this.GetState(Key.RectangleState);
        }
        set rectanglesState(value: string){
            this.SetState(Key.RectangleState, value);
        }
    // Component Circle ********************************************************************************************************************************************

    @Input()
        get circlesState(){
            return this.GetState(Key.CircleState);
        }
        set circlesState(value: string){
            this.SetState(Key.CircleState, value);
        }


    // Component Label main tension********************************************************************************************************************************************
    @Input() labelMainTensionsState: string;

    // Component Label circles ********************************************************************************************************************************************
    @Input() labelCirclesState: string;


    // Component Dimensions ********************************************************************************************************************************************
    @Input()
        get resourcesState(){
            return this.GetState(Key.ResourcesState);
        }
        set resourcesState(value: string){
            this.SetState(Key.ResourcesState, value);
        }

    @Input()
        get valueCreationState(){
            return this.GetState(Key.ValueCreationState);
        }
        set valueCreationState(value: string){
            this.SetState(Key.ValueCreationState, value);
        }

    @Input()
        get leadershipState(){
            return this.GetState(Key.LeadershipState);
        }
        set leadershipState(value: string){
            this.SetState(Key.LeadershipState, value);
        }

    @Input()
        get selfLeadershipState(){
            return this.GetState(Key.SelfLeadershipState);
        }
        set selfLeadershipState(value: string){
            this.SetState(Key.SelfLeadershipState, value);
        }

    @Input()
        get dimensionResourcesState(){
            return this.GetState(Key.DimensionResources);
        }
        set dimensionResourcesState(value: string){
            this.SetState(Key.DimensionResources, value);
        }

    @Input()
        get dimensionValueCreationState(){
            return this.GetState(Key.DimensionValueCreation);
        }
        set dimensionValueCreationState(value: string){
            this.SetState(Key.DimensionValueCreation, value);
        }

    @Input()
        get dimensionLeadershipState(){
            return this.GetState(Key.DimensionLeadership);
        }
        set dimensionLeadershipState(value: string){
            this.SetState(Key.DimensionLeadership, value);
        }

    @Input()
        get dimensionSelfLeadershipState(){
            return this.GetState(Key.DimensionSelfLeadership);
        }
        set dimensionSelfLeadershipState(value: string){
            this.SetState(Key.DimensionSelfLeadership, value);
        }

    //**********************************************************************************************************************************************************
    ngOnInit() {
        this.getProject();
        if (this.mode == "demo") {
            this.DefineClick(0);
        }
        this.ContextActions.Register(Key.MainTensions, this.CmpLabelMainTensions);
    }

    getProject(): void {
        this.projectService.getProject()
            .subscribe(project => {
                this.project = project;
            });
    }

    ToStateByClientState(tensionName: string =""){
        this.resourcesState =  tensionName + this.ClientState;
        this.valueCreationState = tensionName + this.ClientState;
        this.leadershipState = tensionName + this.ClientState;
        this.selfLeadershipState = tensionName + this.ClientState;
    }

    ToLogo(){
        this.ClientState = StateValue.Logo;
        this.RegisterState({ Key : Key.ViewClient, StateValue : StateValue.ShowBlue });
        this.RegisterState({ Key : Key.Logo, StateValue : StateValue.Show });
    }

    ToMainProblem(){
        this.ClientState = StateValue.MainProblem;

        this.logoState = StateValue.MainProblem;
        this.viewClientState = StateValue.ShowWhite;
        this.mainProblemState = StateValue.Show;
    }

    ToTensions(){
        if (this.ClientState == StateValue.MainProblem){
            this.ClientState = StateValue.Tensions;
            this.SetStateAction(Key.MainProblem, StateValue.HiddenDescription,
            (value) => {
                if (value ==  StateValue.Hidden){
                    this.RemoveNotification(Key.MainProblem, StateValue.HiddenDescription);
                    this.ToStateByClientState();
                }
            });
        } else {
            this.ClientState = StateValue.Tensions;
            this.viewClientState = StateValue.ShowWhite;

            this.logoState = StateValue.FullHidden;
            this.ToStateByClientState();
        }
    }

    ToMissions(){
        if (this.ClientState == StateValue.Edition){
            this.ClientState = StateValue.Missions;

            this.SetStateAction(this.CurrentDimension + 'State',StateValue.Missions,
                (value)=>{
                    this.RemoveNotification(this.CurrentDimension + 'State',StateValue.Missions);
                    if (this.CurrentDimension != Key.Resources){
                        this.dimensionResourcesState = StateValue.Initial;
                    }

                    if (this.CurrentDimension != Key.ValueCreation){
                        this.dimensionValueCreationState = StateValue.Initial;
                    }

                    if (this.CurrentDimension != Key.Leadership){
                        this.dimensionLeadershipState = StateValue.Initial;
                    }

                    if (this.CurrentDimension != Key.SelfLeadership){
                        this.dimensionSelfLeadershipState = StateValue.Initial;
                    }
                });
        } else {
            if (this.ClientState != StateValue.Tensions && this.ClientState != StateValue.Mission){
                this.viewClientState = StateValue.ShowWhite;
                this.logoState = StateValue.FullHidden;
            }
            this.ClientState = StateValue.Missions;
            this.ToStateByClientState();
        }
    }

    ToMission(tensionName: string){
       this.ClientState = StateValue.Mission;
       this.ToStateByClientState(tensionName);
    }

    private SetEdition(tensionName: string){
        this.ClientState = StateValue.Edition;
        this.CurrentDimension = tensionName;

        var newState = "ToCornerFrom" + tensionName + "Mission"

        if (tensionName == Key.Resources) {
            this.resourcesState = tensionName + this.ClientState;
        } else {
            this.dimensionResourcesState = newState;
        }

        if (tensionName == Key.ValueCreation) {
            this.valueCreationState = tensionName + this.ClientState;
        } else {
            this.dimensionValueCreationState = newState;
        }

        if (tensionName == Key.Leadership) {
            this.leadershipState = tensionName + this.ClientState;
        } else {
            this.dimensionLeadershipState = newState;
        }

        if (tensionName == Key.SelfLeadership) {
            this.selfLeadershipState = tensionName + this.ClientState;
        } else {
            this.dimensionSelfLeadershipState = newState;
        }
    }

    ToEdition(tensionName: string){
        if (this.ClientState != StateValue.Missions ){

            this.viewClientState = StateValue.ShowWhite;
            this.logoState = StateValue.FullHidden;

            this.resourcesState =  StateValue.Missions;
            this.valueCreationState = StateValue.Missions;
            this.leadershipState = StateValue.Missions;

            this.SetStateAction(Key.SelfLeadershipState,StateValue.Missions,
                () => {
                    this.RemoveNotification(Key.SelfLeadershipState,StateValue.Missions);
                    this.ClientState = StateValue.Edition;
                    this.CurrentDimension = tensionName;
                    this.SetEdition(tensionName);
                });
        } else {
            this.SetEdition(tensionName);
        }
    }

    ReloadProject(){
        this.getProject();
    }

    public ExecuteAction(msg: Message) {
        self.ContextActions.Execute(
            [ { 'Context': 'viewClient' , 'Action' : msg.content.Action } ] );
    }

//********DO DELETE***********************************************************************************************************
    public DefineClick(id:number){
        document.addEventListener('click', this['CustomClick' + id]);
    }

    public CustomClick0(){
        document.removeEventListener("click",self.CustomClick0);
        self.ContextActions.Execute(
            [ { 'Context': 'viewClient' , 'Action' : 'ToMainProblem'  } ] )
        self.DefineClick(1);
    }

    public CustomClick1(){
        document.removeEventListener("click",self.CustomClick1);
        self.ContextActions.Execute(
            [{ 'Context': 'viewClient' , 'Action' : 'ToTensions' } ] )
            //self.DefineClick(2);
            self.DefineClick(13);
        }

    public CustomClick2(){
        document.removeEventListener("click",self.CustomClick2);
        self.ContextActions.Execute(
            [{ 'Context': 'viewClient' , 'Action' : 'ToMissions' } ] )
        self.DefineClick(3);
    }

    public CustomClick3(){
        document.removeEventListener("click",self.CustomClick3);
        self.ContextActions.Execute(
            [{ 'Context': 'viewClient' , 'Action' : 'ToMission' , 'Parameters' : [ 'Resources' ] } ] )
        self.DefineClick(4);
    }

    public CustomClick4(){
        document.removeEventListener("click",self.CustomClick4);
        self.ContextActions.Execute(
            [{ 'Context': 'viewClient' , 'Action' : 'ToMission' , 'Parameters' : [ 'ValueCreation' ] } ] )
        self.DefineClick(5);
    }

    public CustomClick5(){
        document.removeEventListener("click",self.CustomClick5);
        self.ContextActions.Execute(
            [{ 'Context': 'viewClient' , 'Action' : 'ToMission' , 'Parameters' : [ 'Leadership' ] } ] )
        self.DefineClick(6);
    }

    public CustomClick6(){
        document.removeEventListener("click",self.CustomClick6);
        self.ContextActions.Execute(
            [{ 'Context': 'viewClient' , 'Action' : 'ToMission' , 'Parameters' : [ 'SelfLeadership' ] } ] )
        self.DefineClick(7);
    }

    public CustomClick7(){
        document.removeEventListener("click",self.CustomClick7);
        self.ContextActions.Execute(
            [{ 'Context': 'viewClient' , 'Action' : 'ToMissions' } ] )
        self.DefineClick(8);
    }

    public CustomClick8(){
        document.removeEventListener("click",self.CustomClick8);
        self.ContextActions.Execute(
            [{ 'Context': 'viewClient' , 'Action' : 'ToEdition' , 'Parameters' : [ 'Resources' ] } ] )
        self.DefineClick(9);
    }

    public CustomClick9(){
        document.removeEventListener("click",self.CustomClick9);
        self.ContextActions.Execute(
            [{ 'Context': 'viewClient' , 'Action' : 'ToMissions' } ] )
        self.DefineClick(10);
    }

    public CustomClick10(){
        document.removeEventListener("click",self.CustomClick10);
        self.ContextActions.Execute(
            [{ 'Context': 'viewClient' , 'Action' : 'ToEdition' , 'Parameters' : [ 'ValueCreation' ] } ] )
        self.DefineClick(11);
    }

    public CustomClick11(){
        document.removeEventListener("click",self.CustomClick11);
        self.ContextActions.Execute(
            [{ 'Context': 'viewClient' , 'Action' : 'ToMissions' } ] )
        self.DefineClick(12);
    }

    public CustomClick12(){
        document.removeEventListener("click",self.CustomClick12);
        self.ContextActions.Execute(
            [{ 'Context': 'viewClient' , 'Action' : 'ToEdition' , 'Parameters' : [ 'Leadership' ] } ] )
        self.DefineClick(13);
    }

    public CustomClick13(){
        document.removeEventListener("click",self.CustomClick13);
        self.ContextActions.Execute(
            [{ 'Context': 'viewClient' , 'Action' : 'ToMissions' } ] )
        self.DefineClick(14);
    }

    public CustomClick14(){
        document.removeEventListener("click",self.CustomClick14);
        self.ContextActions.Execute(
            [{ 'Context': 'viewClient' , 'Action' : 'ToEdition' , 'Parameters' : [ 'SelfLeadership' ] } ] )
        self.DefineClick(15);
    }

    public CustomClick15(){
        document.removeEventListener("click",self.CustomClick15);
        self.ContextActions.Execute(
            [{ 'Context': 'viewClient' , 'Action' : 'ToMissions' } ] )
        self.DefineClick(16);
    }

}

let self: ViewClientComponent;
