import { NgModule, Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { ViewClientComponent } from './components/view-client/view-client.component';
import { BTLibraryComponent } from './btlibrary.component';
import { MainProblemComponent } from './components/main-problem/main-problem.component';
import { LabelMainTensionsComponent } from './components/label-main-tensions/label-main-tensions.component';
import { SliderTextComponent } from './controls/slider-text/slider-text.component';
import { SliderComponent } from './controls/slider/slider.component';
import { TrendComponent } from './controls/trend/trend.component';
import { InitiativeComponent } from './controls/initiative/initiative.component';
import { ArrowComponent } from './controls/arrow/arrow.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { ValueCreationComponent } from './components/value-creation/value-creation.component';
import { LeadershipComponent } from './components/leadership/leadership.component';
import { SelfLeadershipComponent } from './components/self-leadership/self-leadership.component';

@NgModule({
    imports: [],
    declarations: [
        BTLibraryComponent,
        ViewClientComponent,
        MainProblemComponent,
        SliderComponent,
        SliderTextComponent,
        TrendComponent,
        InitiativeComponent,
        ArrowComponent,
        LabelMainTensionsComponent,
        ResourcesComponent,
        ValueCreationComponent,
        LeadershipComponent,
        SelfLeadershipComponent,
    ],
    exports: [
        BTLibraryComponent,
        ViewClientComponent,
        SliderComponent,
        SliderTextComponent,
        TrendComponent,
        InitiativeComponent,
    ],
    entryComponents: [ViewClientComponent]
})
export class BTLibraryModule {
  constructor(private injector: Injector) {
  }

  ngDoBootstrap() {
    const el = createCustomElement(ViewClientComponent, {injector : this.injector});
    customElements.define('btviewclientcomponent', el);
  }
}
