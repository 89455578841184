import { Action } from './Action';

export class Message {
    constructor(
        public sender: string,
        public content: Action,
        public isBroadcast = true,
    ) {
    }
}
