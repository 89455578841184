export class ElementState {
    /**
     *Creates an instance of ElementState.
     * @param {string} Key
     * @param {*} Owner
     * @param {Function} Start
     * @param {Function} Done
     * @param {string} [State]
     * @memberof ElementState
     */
    constructor(
        public Key: string,
        public Owner: any,
        public Notify: Function,
        public Start: Function,
        public Done: Function,
        public State?: string){
        }

    public QueueStart: Function[] = new Array();
    public QueueDone: Function[] = new Array();
    public InvetoryNotifications : { [state:string] : Function} = {};

    public RemoveNotification(value: string){
        if (this.InvetoryNotifications[value]){
            delete this.InvetoryNotifications[value];
        }
    }
}
