import { Component, OnInit } from '@angular/core';
import { Project } from 'projects/btlibrary/src/lib/data/Project';
import { ProjectService } from 'projects/btlibrary/src/lib/services/project.service';
import { ViewClientComponent } from 'projects/btlibrary/src/lib/components/view-client/view-client.component';
import { MessagingService } from 'projects/btlibrary/src/lib/services/messaging.service';

@Component({
  selector: 'bt-dimensions',
  templateUrl: './dimensions.component.html',
  styleUrls: ['./dimensions.component.css']
})
export class DimensionsComponent implements OnInit {
  project: Project;

  constructor(
    private projectService: ProjectService,
    private messagingService: MessagingService
  ) {
  }

  ngOnInit() {
    this.getProject();
  }

  getProject(): void {
    this.projectService.getProject()
        .subscribe(project => {
          this.project = project;
          this.Confirm(-1);
        });
  }

  public Confirm(dim: number) {
    switch (dim) {
      case -1:
        this.messagingService.SendMessage('ToTensions');
        break;
      case 0:
        // this.messagingService.SendMessage('MainProblemToTensions');
        break;
    }
  }
}
