import { Component, OnInit } from '@angular/core';
import { Project } from 'projects/btlibrary/src/lib/data/Project';
import { Tension } from 'projects/btlibrary/src/lib/data/Tension';
import { ProjectService } from 'projects/btlibrary/src/lib/services/project.service';
import { ViewClientComponent } from 'projects/btlibrary/src/lib/components/view-client/view-client.component';
import { Initiative } from 'projects/btlibrary/src/lib/data/Initiative';

@Component({
  selector: 'bt-initiative-detail',
  templateUrl: './initiative-detail.component.html',
  styleUrls: ['./initiative-detail.component.css']
})
export class InitiativeDetailComponent implements OnInit {
  project: Project;
  curTension: Tension;
  curInitiative: Initiative;

  constructor(private projectService: ProjectService) { }

  ngOnInit() {
    this.getProject();
  }

  getProject(): void {
    this.projectService.getProject()
        .subscribe(project => {
          this.project = project;
          this.curTension = this.project.tensions[3];
          this.curInitiative = this.curTension.initiatives[0];
        });
  }

  public UpdateInitiative() {
  }

  public Confirm(dim: number) {
    switch (dim) {
      case 0:
        // ViewClientComponent.self.ContextActions.Execute(
        //  [ { 'Context': 'ViewClient' , 'Action' : 'DefineDimensionToSpecifyDimension' , 'Parameters' : [ 'Resources' ]} ] );
        break;
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
    }
  }
}
