import { Component, OnInit } from '@angular/core';
import { Project } from 'projects/btlibrary/src/lib/data/Project';
import { Tension } from 'projects/btlibrary/src/lib/data/Tension';
import { ProjectService } from 'projects/btlibrary/src/lib/services/project.service';
import { ViewClientComponent } from 'projects/btlibrary/src/lib/components/view-client/view-client.component';
import { Group } from 'projects/btlibrary/src/lib/data/Group';

@Component({
  selector: 'bt-grouping-detail',
  templateUrl: './grouping-detail.component.html',
  styleUrls: ['./grouping-detail.component.css']
})
export class GroupingDetailComponent implements OnInit {
  project: Project;
  curTension: Tension;
  curGroup: Group;

  constructor(private projectService: ProjectService) { }

  ngOnInit() {
    this.getProject();
  }

  getProject(): void {
    this.projectService.getProject()
        .subscribe(project => {
          this.project = project;
          this.curTension = this.project.tensions[0];
          this.curGroup = this.curTension.groupTrends[0];
          this.curGroup.trends.push(this.curTension.trends[1]);
          this.curGroup.trends.push(this.curTension.trends[2]);
          this.curTension.groupTrends[1].trends.push(this.curTension.trends[0]);
          this.curTension.groupTrends[1].trends.push(this.curTension.trends[3]);
        });
  }

  public UpdateGroup() {
  }

  public Confirm(dim: number) {
    switch (dim) {
      case 0:
        // ViewClientComponent.self.ContextActions.Execute(
        //  [ { 'Context': 'ViewClient' , 'Action' : 'DefineDimensionToSpecifyDimension' , 'Parameters' : [ 'Resources' ]} ] );
        break;
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
    }
  }
}
