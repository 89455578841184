import { DimensionsStates } from './dimensions-states';
import { Key, StateValue } from './enums';
import { AnimationsData } from './animations';
import { SvgText,  TextAnchorWidth, TextAnchorHeight } from '../platform'
import { Input, ViewChild, ElementRef } from '@angular/core';

export class Dimension  extends DimensionsStates  {

    constructor(
        public AnimationInfo: AnimationsData,
        private Id: string
    ) {
        super();
        // rectangle
        this.RegisterState({ Key : Key.Rectangle, StateValue : StateValue.FullHidden });

        // main tension name
        this.RegisterState({ Key : Key.TensionNameTension, StateValue : StateValue.FullHidden });
        this.RegisterState({ Key : Key.TensionNameMission, StateValue : StateValue.FullHidden });
        this.RegisterState({ Key : Key.TensionNameEdition, StateValue : StateValue.FullHidden });

        // circles external and internal
        this.RegisterState({ Key : Key.CircleExternal, StateValue : StateValue.FullHidden });
        this.RegisterState({ Key : Key.CircleInternal, StateValue : StateValue.FullHidden });
        this.RegisterState({ Key : Key.CircleExternalFull, StateValue : StateValue.FullHidden });
        this.RegisterState({ Key : Key.CircleInternalFull, StateValue : StateValue.FullHidden });

        // tensions name external and internal
        this.RegisterState({ Key : Key.TensionNameExternal, StateValue : StateValue.FullHidden });
        this.RegisterState({ Key : Key.TensionNameInternal, StateValue : StateValue.FullHidden });
    }
    private tensionName_:string;
    private tensionNameExternal_:string;
    private tensionNameInternal_:string;

    @Input()
        get tensionName(){
            return this.tensionName_;
        }
        set tensionName(value: string){
            this.tensionName_ = value;
            SvgText(this.tensionNameTensionDOM, value, 50, 80, TextAnchorWidth.middle, this.AnimationInfo.RectangleHeight, TextAnchorHeight.middle, this.AnimationInfo.RectangleWidth);
            SvgText(this.tensionNameMissionDOM, value, 50, 50, TextAnchorWidth.middle, this.AnimationInfo.RectangleHeight, TextAnchorHeight.middle, this.AnimationInfo.RectangleWidth);
            SvgText(this.tensionNameEditionDOM, value, 50, 80, TextAnchorWidth.middle, this.AnimationInfo.EditionWidth, TextAnchorHeight.middle, this.AnimationInfo.TensionNameEditionHeight);
        }

    @Input()
        get tensionNameExternal(){
            return this.tensionNameExternal_;
        }
        set tensionNameExternal(value: string){
            this.tensionNameExternal_ = value;
            SvgText(this.tensionNameExternalDOM, value, 40, 50, TextAnchorWidth.middle, this.AnimationInfo.TensionNameWidth, TextAnchorHeight.middle, this.AnimationInfo.TensionNameHeight);
        }

    @Input()
        get tensionNameInternal(){
            return this.tensionNameInternal_;
        }
        set tensionNameInternal(value: string){
            this.tensionNameInternal_ = value;
            SvgText(this.tensionNameInternalDOM, value, 40, 50, TextAnchorWidth.middle, this.AnimationInfo.TensionNameWidth, TextAnchorHeight.middle, this.AnimationInfo.TensionNameHeight);
        }

    // Rectangle ***********************************************************************************************************************************************
    @Input()
        get RectangleState(){
            return this.GetState(Key.Rectangle);
        }
        set RectangleState(value: string){
            this.SetState(Key.Rectangle, value);
        }

    // TensionName ***********************************************************************************************************************************************
    @ViewChild(Key.TensionNameTension) tensionNameTensionDOM: ElementRef;
    @Input()
        get TensionNameTensionState(){
            return this.GetState(Key.TensionNameTension);
        }
        set TensionNameTensionState(value: string){
            this.SetState(Key.TensionNameTension, value);
        }

    @ViewChild(Key.TensionNameMission) tensionNameMissionDOM: ElementRef;
    @Input()
        get TensionNameMissionState(){
            return this.GetState(Key.TensionNameMission);
        }
        set TensionNameMissionState(value: string){
            this.SetState(Key.TensionNameMission, value);
        }

    @ViewChild(Key.TensionNameEdition) tensionNameEditionDOM: ElementRef;
    @Input()
        get TensionNameEditionState(){
            return this.GetState(Key.TensionNameEdition);
        }
        set TensionNameEditionState(value: string){
            this.SetState(Key.TensionNameEdition, value);
        }

    // TensionNameSub ***********************************************************************************************************************************************
    @ViewChild(Key.TensionNameExternal) tensionNameExternalDOM: ElementRef;
    @Input()
        get TensionNameExternalState(){
            return this.GetState(Key.TensionNameExternal);
        }
        set TensionNameExternalState(value: string){
            this.SetState(Key.TensionNameExternal, value);
        }

    @ViewChild(Key.TensionNameInternal) tensionNameInternalDOM: ElementRef;
    @Input()
        get TensionNameInternalState(){
            return this.GetState(Key.TensionNameInternal);
        }
        set TensionNameInternalState(value: string){
            this.SetState(Key.TensionNameInternal, value);
        }

    // Circle ***********************************************************************************************************************************************
    public EditionCircleExternalPosX: number = this.AnimationInfo.EditionCircleExternalPosX;
    public EditionCircleInternalPosX: number = this.AnimationInfo.EditionCircleInternalPosX;
    public EditionCirclePosY: number = this.AnimationInfo.EditionCirclePosY;
    public EditionHeight: number = this.AnimationInfo.EditionHeight;
    @Input()
        get CircleExternalState(){
            return this.GetState(Key.CircleExternal);
        }
        set CircleExternalState(value: string){
            this.SetState(Key.CircleExternal, value);
        }

    @Input()
        get CircleInternalState(){
            return this.GetState(Key.CircleInternal);
        }
        set CircleInternalState(value: string){
            this.SetState(Key.CircleInternal, value);
        }

    @Input()
        get CircleExternalFullState(){
            return this.GetState(Key.CircleExternalFull);
        }
        set CircleExternalFullState(value: string){
            this.SetState(Key.CircleExternalFull, value);
        }

        @Input()
        get CircleInternalFullState(){
            return this.GetState(Key.CircleInternalFull);
        }
        set CircleInternalFullState(value: string){
            this.SetState(Key.CircleInternalFull, value);
        }

    //************************************************************************************************************************************************************************************************
    private SetValue(value: StateValue) {
        this.SetStateAction(Key.Rectangle, value, null, null,
            ()=>{
                this.TriggerOwnerStateChange(this.Id + 'DoneMissions')
            });

        this.TensionNameTensionState = StateValue.FullHidden;
        this.TensionNameMissionState = value;
        this.CircleExternalState = value;
        this.CircleInternalState = value;
        this.TensionNameExternalState = value;
        this.TensionNameInternalState = value;
    }

    public SetStateTensions(oldValue: StateValue, newValue: StateValue){
        var value = StateValue.Tension;
        this.SetStateAction(Key.Rectangle, value, null, null,
            () => {
                this.TensionNameTensionState = value;
            })
    }

    public SetStateMissions(oldValue: StateValue, newValue: StateValue){
        switch (oldValue){
            case StateValue.ResourcesEdition:
                this.SetMissionFromEdition(Key.Resources, StateValue.ToCorner);
                break;
            case StateValue.ValueCreationEdition:
                this.SetMissionFromEdition(Key.ValueCreation, StateValue.ToCorner);
                break;
            case StateValue.LeadershipEdition:
                this.SetMissionFromEdition(Key.Leadership, StateValue.ToCorner);
                break;
            case StateValue.SelfLeadershipEdition:
                this.SetMissionFromEdition(Key.SelfLeadership, StateValue.ToCorner);
                break;
            default:
                this.SetValue(StateValue.Mission);
                break;
        }
    }

    public SetStateMission(key: Key){
        if (this.Id == key){
            this.SetValue(StateValue.Mission);
        } else {
            this.SetValue(StateValue.MissionUnfocus);
        }
    }

    public SetStateEdition(key: Key, state: StateValue){
        if (this.Id == key){
            this.CircleExternalState = state;
            this.CircleInternalState = state;
            this.TensionNameMissionState = state;
            this.TensionNameExternalState = state;
            this.TensionNameInternalState = state;
            this.SetStateAction(Key.Rectangle, state, null, null,
                () => {
                    this.CircleExternalState = StateValue.Edition;
                    this.TensionNameMissionState = StateValue.Edition;
                    this.TensionNameExternalState = StateValue.ToCornerTop;
                    this.SetStateAction(Key.Rectangle, StateValue.Edition, null, null,
                    () => {
                        this.TensionNameExternalState = StateValue.Edition;
                        this.TensionNameInternalState = StateValue.Edition;
                        this.TensionNameEditionState = StateValue.Edition;
                        this.CircleExternalState = StateValue.EditionHidden;
                        this.CircleInternalState = StateValue.ToCornerHidden;
                        this.CircleExternalFullState =  StateValue.Edition;
                        this.CircleInternalFullState =  StateValue.Edition;
                    });
                });
        }
    }
    private SetMissionFromEdition(key: Key, value: string) {
        if (this.Id == key) {
            this.CircleInternalFullState    = StateValue.ToCornerTop;
            this.TensionNameExternalState   = StateValue.ToCornerTop;
            this.TensionNameInternalState   = StateValue.ToCorner;
            this.SetStateAction(Key.CircleExternalFull, StateValue.ToCornerTop, null, null,
                () => {
                     this.TensionNameEditionState    = StateValue.ToCorner;
                     this.TensionNameMissionState    = StateValue.ToCorner;
                     this.CircleExternalState        = StateValue.ToCorner;
                     this.CircleInternalState        = StateValue.ToCorner;
                     this.TensionNameExternalState   = StateValue.ToCorner;
                     this.CircleExternalFullState    = StateValue.FullHidden;
                     this.CircleInternalFullState    = StateValue.FullHidden;
                     this.SetStateAction(Key.Rectangle, StateValue.ToCorner, null, null,
                        () => {
                            this.TriggerOwnerStateChange("PosAnimationToCornerDone");
                            this.RectangleState             = StateValue.Mission;
                            this.TensionNameEditionState    = StateValue.FullHidden;
                            this.TensionNameMissionState    = StateValue.Mission;
                            this.CircleExternalState        = StateValue.Mission;
                            this.CircleInternalState        = StateValue.Mission;
                            this.TensionNameExternalState   = StateValue.Mission;
                            this.TensionNameInternalState   = StateValue.Mission;
                        });
                });
        }
    }
}
