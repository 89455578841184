import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { Project } from 'projects/btlibrary/src/lib/data/Project';
import { Tension } from 'projects/btlibrary/src/lib/data/Tension';
import { ProjectService } from 'projects/btlibrary/src/lib/services/project.service';
import { MessagingService } from 'projects/btlibrary/src/lib/services/messaging.service';
import { SliderComponent } from 'projects/btlibrary/src/lib/controls/slider/slider.component';
import { TensionModel } from 'projects/btlibrary/src/lib/data/TensionModel';
import { Subtension } from 'projects/btlibrary/src/lib/data/Subtension';
import { ViewClientComponent } from 'projects/btlibrary/src/lib/components/view-client/view-client.component';
import { TensionTypeString } from '../../../../../btlibrary/src/lib/class/application/enums';

@Component({
  selector: 'bt-subtension',
  templateUrl: './subtension.component.html',
  styleUrls: ['./subtension.component.css']
})
export class SubtensionComponent implements OnInit {
  project: Project;
  curTension: Tension;
  curSubTension: Subtension;

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private messagingService: MessagingService
    ) {
    }

  private idTension: number;

  ngOnInit() {
    this.route.params.subscribe(params => {
        this.idTension = +params['id']; // (+) converts string 'id' to a number
    });

    this.getProject();
  }

  getProject(): void {
    this.projectService.getProject()
        .subscribe(project => {
          this.project = project;
          this.curTension = this.project.tensions[this.idTension];
          TensionModel.recalculateTension(this.curTension);
          this.curSubTension = this.curTension.subtensions[0];

          this.ToEdition(TensionTypeString[this.idTension]);
        });
  }

  public UpdateSubtension() {
    TensionModel.recalculateTension(this.curTension);
  }

  ToEdition(tensionName: string) {
    this.messagingService.SendMessage('ToEdition', [ tensionName ]);
  }
}
